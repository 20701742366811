import axios from 'axios'
import { useProvideActivationApi } from '@lasso/api-activation/activation'
import { useProvideActivationLegacyApi } from '@lasso/api-activation/activation/legacy'
import { formatError } from '@lasso/api-shared'
import { useAuthInterceptors, useProvideAuthApi } from '@lasso/shared-auth'

export const useInitApi = () => {
  const axiosInstance = axios.create({
    baseURL: process.env.PUBLIC_ACT_API_URL || '',
  })
  const axiosLegacyInstance = axios.create({
    baseURL: process.env.PUBLIC_API_URL || '',
  })

  useProvideAuthApi(axiosLegacyInstance)

  const { setAuthToken, handleUnauthorized } = useAuthInterceptors()

  ;[axiosInstance, axiosLegacyInstance].forEach((instance) => {
    instance.interceptors.request.use(setAuthToken)
    instance.interceptors.response.use(undefined, handleUnauthorized)
    instance.interceptors.response.use(undefined, formatError)
  })

  useProvideActivationApi(axiosInstance)
  useProvideActivationLegacyApi(axiosLegacyInstance)
}
