<template>
  <button type="button" class="button-icon" :class="buttonClasses" :disabled="disabled" @click="onClick">
    <slot>
      <Icon :icon="icon" :variant="iconVariant" />
    </slot>
  </button>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import Icon from '../Icon/Icon.vue'
import { checkForUndefined } from '../../utils'
import { useButtonGroupProps } from '../ButtonGroup/useButtonGroupProps'

import type { ButtonIconProps } from './types'
import * as classes from './classes'

const {
  value,
  variant,
  color = 'default',
  size,
  isActive = false,
  disabled = false,
  shape,
  iconVariant = 'round',
  block = false,
} = defineProps<ButtonIconProps>()

const emit = defineEmits<{
  click: [e: Event]
}>()

const buttonGroupProps = useButtonGroupProps()

const isActiveInternal = computed(() => {
  return buttonGroupProps?.value.isActive(value) ?? isActive
})

const buttonClasses = computed(() => {
  const _color = checkForUndefined(color, classes.color)
  const _variant = checkForUndefined(variant || buttonGroupProps?.value.variant || 'text', classes.variant)
  const _size = checkForUndefined(size || buttonGroupProps?.value.size || '3xs', classes.size)
  const _shape = checkForUndefined(shape || buttonGroupProps?.value.shape || 'none', classes.shape)

  return [_color, _variant, _shape, _size, {
    'button-icon-active': isActiveInternal.value,
    'button-icon-block': block,
  }]
})

const onClick = (e: Event) => {
  emit('click', e)

  if (buttonGroupProps) {
    buttonGroupProps.value.setActive(value)
  }
}
</script>

<style scoped src="./buttonIcon.styles.css" />
