<template>
  <Paper class="root" variant="outlined" rounded="xl" px="4" py="4">
    <div class="grid">
      <ButtonIcon
        v-if="conditions.fields.length > 1"
        class="remove"
        icon="close"
        :disabled="formDisabled"
        @click="onRemove()"
      />
      <FormControl
        :id="getId(`conditions[${index}].type`)"
        :disabled="formDisabled"
        required
        label="Include People Who"
        :tooltipProps="{ maxWidth: 'auto' }"
      >
        <template #default="{ id, disabled }">
          <Select
            :id="id"
            v-model="typeInternal"
            :options="typeOptions"
            :disabled="disabled"
            placeholder="Select"
          />
        </template>
        <template #tooltip>
          Set rules using different URL parts:<br><br>

          URL Example: https://www.example.com/path/to/resource?utm=123<br>
          &#8226; Domain: http://www.example.com<br>
          &#8226; Path: path/to/resource<br>
          &#8226; Query String: utm=123
        </template>
      </FormControl>
      <FormControl
        v-if="isConditionWithQualifiers(type)"
        :id="getId(`conditions[${index}].comparison`)"
        v-slot="{ id, disabled }"
        :disabled="formDisabled"
        required
        label="That"
      >
        <Select
          :id="id"
          v-model="comparisonInternal"
          :options="comparisonOptions"
          :disabled="disabled"
          placeholder="Select"
        />
      </FormControl>
      <FormControl
        v-if="isConditionWithQualifiers(type)"
        :id="getId(`conditions[${index}].keywords`)"
        v-slot="{ id, disabled }"
        :disabled="formDisabled"
        required
        label="Keywords"
        class="keywords"
      >
        <InputChips
          :id="id"
          v-model="keywords"
          :disabled="disabled"
          separator="or"
          allowSpace
          :placeholder="keywordsPlaceholder"
        />
      </FormControl>
    </div>
  </Paper>
</template>

<script setup lang="ts">
import { ButtonIcon, FormControl, InputChips, Paper, Select, buildSelectOptionsFromRecord } from '@lasso/luikit'
import { UniversalPixelConditionComparison, UniversalPixelConditionType } from '@lasso/api-activation/activation'
import { computed } from 'vue'

import { isConditionWithQualifiers } from './utils'
import { useAudienceForm } from './useAudienceForm'

const { index } = defineProps<{
  index: number
}>()

const { getId, formDisabled, conditions, useFieldArrayModels } = useAudienceForm()!

const {
  type,
  comparison,
  keywords,
} = useFieldArrayModels(
  'conditions',
  ['type', 'comparison', 'keywords'],
  () => index,
)

const typeInternal = computed({
  get: () => type.value || '',
  set: (value) => {
    type.value = value || undefined
  },
})

const comparisonInternal = computed({
  get: () => comparison.value || '',
  set: (value) => {
    comparison.value = value || undefined
  },
})

const typeOptions = buildSelectOptionsFromRecord({
  [UniversalPixelConditionType.VIEW_ANY_PAGE]: 'View any page',
  [UniversalPixelConditionType.VIEW_DOMAIN]: 'View a domain',
  [UniversalPixelConditionType.VIEW_PATH]: 'View a path',
  [UniversalPixelConditionType.VIEW_URL]: 'View a URL',
  [UniversalPixelConditionType.VIEW_QUERY_STRING]: 'View a query string',
} satisfies Record<UniversalPixelConditionType, string>)

const comparisonOptions = buildSelectOptionsFromRecord({
  [UniversalPixelConditionComparison.CONTAINS]: 'Contains',
  [UniversalPixelConditionComparison.NOT_CONTAINS]: 'Does not contain',
  [UniversalPixelConditionComparison.EQUALS]: 'Equals',
  [UniversalPixelConditionComparison.NOT_EQUALS]: 'Does not equal',
} satisfies Record<UniversalPixelConditionComparison, string>)

const keywordsPlaceholders: Record<UniversalPixelConditionType, string> = {
  [UniversalPixelConditionType.VIEW_ANY_PAGE]: '',
  [UniversalPixelConditionType.VIEW_PATH]: 'Keywords within the path',
  [UniversalPixelConditionType.VIEW_DOMAIN]: 'Keywords within the domain',
  [UniversalPixelConditionType.VIEW_URL]: 'Keywords within the url',
  [UniversalPixelConditionType.VIEW_QUERY_STRING]: 'Keywords within the query string',
}

const keywordsPlaceholder = computed(() => type.value ? keywordsPlaceholders[type.value] : '')

const onRemove = () => {
  conditions.remove(index)
}
</script>

<style scoped>
.grid {
  @apply gap-4;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.remove {
  position: absolute;
  top: 0;
  right: 0;
}

.keywords {
  grid-column: span 2;
}
</style>
