import { navigateToAppUrl } from '@lasso/shared/utils'
import { InternalAxiosRequestConfig, isAxiosError } from 'axios'
import { useUser } from '@lasso/shared/hooks'
import { ApiError } from '@lasso/api-shared'
import { useRoute } from 'vue-router'

import { useMounted } from '@vueuse/core'

import { useAuth } from './useAuth'

export const useAuthInterceptors = () => {
  const { authInfo, clearUserData } = useUser()
  const { setAuthReturnLink, loading } = useAuth()
  const route = useRoute()
  const mounted = useMounted()

  /**
   * Request interceptor.
   * Adds the authorization token from local storage to request headers.
   */
  const setAuthToken = (config: InternalAxiosRequestConfig) => {
    const token = authInfo.value?.token

    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  }

  const isErrorStatus = (error: unknown, status: number) => {
    return (isAxiosError(error) && error.response?.status === status)
      || (error instanceof ApiError && error.status === status)
  }

  let isRedirecting = false

  /**
   * Response error interceptor.
   * Redirects to 403 page on 403 error or login page on 401 error.
   */
  const handleUnauthorized = async (error: unknown) => {
    if (!isRedirecting && !loading.value && mounted.value) {
      if (isErrorStatus(error, 403)) {
        isRedirecting = true
        navigateToAppUrl('/pages/403')
      }
      else if (isErrorStatus(error, 401) && !route.path.startsWith('/app/auth')) {
        setAuthReturnLink()
        isRedirecting = true
        clearUserData()
        navigateToAppUrl('/app/auth')
      }
    }

    return Promise.reject(error)
  }

  return {
    setAuthToken,
    handleUnauthorized,
  }
}
