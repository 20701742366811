import { initRouter, registerApplications, startApplication } from '@root-config/singleSpa'
import { config, getRedirectPath } from '@root-config/config'
import { setLocale } from 'yup'
import { defaultMessages, extendYup } from '@lasso/shared/validation'
import { patchGlobalStorage } from '@lasso/shared/hooks'

import '@root-config/global/setWebpackPublicPath'
import '@root-config/global/errorHandlers'
import '@root-config/global/initLoaders'
import '@root-config/styles/main.css'

patchGlobalStorage()
extendYup()
setLocale(defaultMessages)

initRouter(getRedirectPath)
registerApplications(config)
startApplication()
