<template>
  <button :type="type" class="button" :class="buttonClasses" :disabled="disabled || loading" @click="onClick">
    <slot name="start">
      <CircularProgress v-if="loading" class="mr-1" />
      <Icon v-if="!loading && startIcon" :icon="startIcon" variant="round" class="mr-1" />
    </slot>
    <slot>
      {{ label }}
    </slot>
    <slot name="end">
      <Icon v-if="endIcon" :icon="endIcon" variant="round" class="ml-1" />
    </slot>
  </button>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { checkForUndefined } from '../../utils'

import Icon from '../Icon/Icon.vue'
import CircularProgress from '../CircularProgress/CircularProgress.vue'
import { useButtonGroupProps } from '../ButtonGroup/useButtonGroupProps'

import type { ButtonProps } from './types'
import * as classes from './classes'

const {
  value,
  type = 'button',
  variant,
  color = 'default',
  size,
  shape,
  isActive = false,
  fullWidth = false,
  isWide = false,
  disabled = false,
  loading = false,
  block = false,
} = defineProps<ButtonProps>()

const emits = defineEmits<{
  click: [e: Event]
}>()

const buttonGroupProps = useButtonGroupProps()

const isActiveInternal = computed(() => {
  return buttonGroupProps?.value.isActive(value) ?? isActive
})

const buttonClasses = computed(() => {
  const _color = checkForUndefined(color, classes.color)
  const _variant = checkForUndefined(variant || buttonGroupProps?.value.variant || 'default', classes.variant)
  const _size = checkForUndefined(size || buttonGroupProps?.value.size || 'md', classes.size)
  const _shape = checkForUndefined(shape || buttonGroupProps?.value.shape || 'none', classes.shape)

  return [_color, _size, _variant, _shape, {
    'button-active': isActiveInternal.value,
    'btn-block': fullWidth,
    'btn-wide': isWide,
    'button-block': block,
  }]
})

const onClick = (e: Event) => {
  if (!disabled && !loading) {
    emits('click', e)

    if (buttonGroupProps) {
      buttonGroupProps.value.setActive(value)
    }
  }
}
</script>

<style scoped src="./button.styles.css" />
