import { MaybeRef, Ref, computed, ref, toRef, toValue, watch } from 'vue'

import { Awaitable } from '../../utils'

import { UseApiRequestMethod } from './types'
import {
  useApiOptions,
} from './internal/useApiOptions'
import { useApiAwaitable, useApiShared, useRefetchOnCacheClear } from './internal/useApiShared'
import {
  UseApiOptionsCache,
  UseApiOptionsImmediate, UseApiOptionsRefetch,
  UseApiOptionsShared,
  UseApiSharedReturnPublic,
} from './internal/types'
import { omitPrivateKeys } from './internal/utils'

export type UseApiInfiniteOptions<Res, Req, ResArray extends any[]> =
  & Partial<
    & UseApiOptionsImmediate
    & UseApiOptionsShared<Res, Req>
    & UseApiOptionsCache
    & UseApiOptionsRefetch<Req>
  >
  & {
    getNextPageParam: (ctx: NextPageParamContext<Res, Req>) => number | null
    initialPageParam?: number
    getDataArray?: (data: Res) => ResArray
    pageSize?: MaybeRef<number>
  }

export type UseApiInfinitePageParamOptions<Res, Req, ResArray extends any[]> = Required<Pick<
  UseApiInfiniteOptions<Res, Req, ResArray>,
  'getNextPageParam' | 'initialPageParam'
>>

export type NextPageParamContext<Res, Req> = {
  responseData: Res
  requestData: Req
  curPage: UseApiInfinitePageParam
}

export type UseApiInfinitePageParam = {
  param: number
  size: number
}

export type UseApiInfiniteReturn<Res, Req, ResArray extends any[]> =
  & Omit<UseApiSharedReturnPublic<Res, Req>, 'data'>
  & {
    requestNextPage: (throwOnError?: boolean) => Promise<Res | null>
    data: Ref<ResArray>
    hasNextPage: Readonly<Ref<boolean>>
    fetchingNextPage: Readonly<Ref<boolean>>
    pageSize: Ref<number>
  }

/**
 * Wrapper for {@link useApi} for using APIs with infinite scroll.
 *
 * @example
 * const { data, loading, fetching, fetchingNextPage, error, requestNextPage, request } = useApiInfinite(
 *   api.getSomethingInfinite,
 *   page => [{
 *     paging: { skip: page.param, size: page.size } // or paging: { pageNumber: page.param, size: page.size }
 *   }],
 *   {
 *    // Return what will be passed into the request data getter
 *    // You can use useApiInfiniteSkip or useApiInfinitePageNumber to avoid copy-pasting the getter
 *    getNextPageParam: ({ responseData, curPage }) => {
 *       // Return null when no more pages to load
 *       return responseData.total > curPage.param + curPage.size ? curPage.param + curPage.size : null
 *    },
 *
 *    // Initially pageParam will be set to 0, you can override that value
 *    initialPageParam: 0,
 *
 *    // This will be passed to the request and next page param getters
 *    pageSize: 10,
 *
 *    // Convenience option to transform the response data into an array that will be merged with previous pages
 *    getDataArray: data => data.items,
 *
 *    // this will automatically reset response data on request data change
 *    refetch: true,
 *   }
 * )
 *
 * // Call on infinite scroll trigger/Load more click
 * requestNextPage()
 *
 * // Call manually to reset response data
 * request()
 */
export const useApiInfinite = <Res, const Req extends any[], ResArray extends any[] = Res[]>(
  requestMethod: UseApiRequestMethod<Res, Req>,
  requestData: (page: UseApiInfinitePageParam) => NoInfer<Req> | null,
  optionsPartial: UseApiInfiniteOptions<Res, Req, ResArray>,
): Awaitable<UseApiInfiniteReturn<Res, Req, ResArray>> => {
  const {
    getNextPageParam,
    initialPageParam = 0,
    getDataArray = data => [data] as ResArray,
    pageSize = ref(0),
  } = optionsPartial
  const options = useApiOptions(optionsPartial)

  let nextPageParam: number | null = initialPageParam
  let lastNextPageParam: number | null = null
  const hasNextPage = ref(true)
  const dataArray = ref(
    options.initialData.value
      ? getDataArray(options.initialData.value)
      : [],
  ) as unknown as Ref<ResArray>
  let isInitialData = true
  const requestDataGetter = () => requestData({
    param: nextPageParam ?? 0,
    size: toValue(pageSize),
  })

  const hookInternal = useApiShared(requestMethod, requestDataGetter, options)

  const clear = () => {
    nextPageParam = initialPageParam
    lastNextPageParam = null
    hasNextPage.value = nextPageParam !== null
    hookInternal.clearData()
    dataArray.value = options.initialData.value
      ? getDataArray(options.initialData.value)
      : [] as unknown as ResArray
    isInitialData = true
  }

  const append = (responseData: Res, requestData: Req) => {
    lastNextPageParam = nextPageParam
    nextPageParam = getNextPageParam({
      responseData,
      requestData,
      curPage: {
        param: nextPageParam!,
        size: toValue(pageSize),
      },
    })
    hasNextPage.value = nextPageParam !== null
    dataArray.value = isInitialData
      ? getDataArray(responseData) as ResArray
      : dataArray.value.concat(getDataArray(responseData)) as ResArray
    isInitialData = false
  }

  hookInternal.onData(append)

  const requestNextPage = async (throwOnError?: boolean): Promise<Res | null> => {
    return hasNextPage.value ? hookInternal.request(throwOnError) : null
  }

  const clearOnNoRequest = () => {
    if (options.clearWhenDisabled.value) {
      clear()
    }
    else {
      hookInternal.lastRequestData.value = null
    }
  }

  const request = async (throwOnError?: boolean): Promise<Res | null> => {
    if (!options.enabled.value || !requestDataGetter()) {
      clearOnNoRequest()
      return null
    }

    clear()

    return requestNextPage(throwOnError)
  }

  const retry = async () => {
    if (hookInternal.error.value) {
      await request()
    }
  }

  const fetchingNextPage = computed(() => !hookInternal.fetching.value && hookInternal.loading.value)

  watch([options.refetch, options.enabled, requestDataGetter], ([refetch, enabled]) => {
    if (!refetch) {
      return
    }

    const curRequestData = lastNextPageParam === null
      ? null
      : requestData({
        param: lastNextPageParam,
        size: toValue(pageSize),
      })
    const isEnabled = enabled && (lastNextPageParam === null || curRequestData)

    if (isEnabled) {
      if (!curRequestData || !options.equalityCheck.value(curRequestData, hookInternal.lastRequestData.value)) {
        clear()
        void requestNextPage()
      }
    }
    else {
      clearOnNoRequest()
    }
  })

  if (options.immediate.value) {
    void request()
  }

  const hook: UseApiInfiniteReturn<Res, Req, ResArray> = omitPrivateKeys({
    ...hookInternal,
    data: dataArray,
    hasNextPage,
    request,
    retry,
    requestNextPage,
    fetchingNextPage,
    clearData: clear,
    pageSize: toRef(pageSize),
  })

  useRefetchOnCacheClear(hook, options)

  return useApiAwaitable(hook)
}

export const useApiInfiniteSkip = <Res, Req, ResArray extends any[]>(
  getTotal: (ctx: NextPageParamContext<Res, Req>) => number,
): UseApiInfinitePageParamOptions<Res, Req, ResArray> => {
  return {
    getNextPageParam: (ctx) => {
      return getTotal(ctx) > ctx.curPage.param + ctx.curPage.size ? ctx.curPage.param + ctx.curPage.size : null
    },
    initialPageParam: 0,
  }
}

export const useApiInfinitePageNumber = <Res, Req, ResArray extends any[]>(
  getTotal: (ctx: NextPageParamContext<Res, Req>) => number,
): UseApiInfinitePageParamOptions<Res, Req, ResArray> => {
  return {
    getNextPageParam: (ctx) => {
      return getTotal(ctx) > ctx.curPage.param * ctx.curPage.size ? ctx.curPage.param + 1 : null
    },
    initialPageParam: 1,
  }
}
