<template>
  <div :class="[s.buttonGroup, s[variant], s[size]]">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { useCssModule } from 'vue'

import { useProvideButtonGroupProps } from './useButtonGroupProps'
import type { ButtonGroupShape, ButtonGroupSize, ButtonGroupVariant } from './types'

const { variant = 'ghost', size = 'md', shape = 'square', ...props } = defineProps<{
  variant?: ButtonGroupVariant
  size?: ButtonGroupSize
  shape?: ButtonGroupShape
  // eslint-disable-next-line vue/prop-name-casing
  'onUpdate:modelValue'?: Function
}>()

const model = defineModel<unknown>()
const s = useCssModule()

useProvideButtonGroupProps(() => ({
  variant,
  size,
  shape,
  isActive: (value) => {
    return props['onUpdate:modelValue'] ? model.value === value : undefined
  },
  setActive: (value) => {
    model.value = value
  },
}))
</script>

<style module>
.buttonGroup {
  @apply flex w-fit h-fit gap-px  overflow-hidden;
}

.buttonGroup button {
  @apply relative;
  transform: none !important;
}

.buttonGroup button:first-child::before {
  @apply hidden;
}

.buttonGroup button:first-child {
  @apply !rounded-l;
}

.buttonGroup button:last-child {
  @apply !rounded-r;
}

/* VARIANTS */
.ghost {
  @apply bg-base-100 border-base-500 border rounded;
}

.ghost button {
  @apply -m-px;
}

.ghost button::before  {
  @apply absolute bg-base-500 h-full left-0 w-px;
  content: '';
}

.text button::before  {
  @apply absolute bg-base-500 -left-px w-px;
  content: '';
}

.text.xs button::before  {
  @apply h-2;
}

.text.sm button::before  {
  @apply h-3;
}

.text.md button::before  {
  @apply h-4;
}

.text.lg button::before  {
  @apply h-5;
}
</style>
