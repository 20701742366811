import { variant as iconVariants } from '../Icon/classes'

import type { ButtonColor, ButtonVariant } from '../Button/types'

import type { ButtonIconShape, ButtonIconSize } from './types'

export const color: Record<ButtonColor, string> = {
  default: '',
  primary: 'button-icon-primary',
  secondary: 'button-icon-secondary',
  info: 'button-icon-info',
  success: 'button-icon-success',
  warning: 'button-icon-warning',
  error: 'button-icon-error',
}

export const variant: Record<ButtonVariant, string> = {
  default: 'button-icon-default', // (default)
  outlined: 'button-icon-outlined',
  text: 'button-icon-text',
  ghost: 'button-icon-ghost',
}

export const size: Record<ButtonIconSize, string> = {
  '3xs': 'button-icon-3xs', // 16px // default
  '2xs': 'button-icon-2xs', // 20px
  'xs': 'button-icon-xs', // 24px
  'sm': 'button-icon-sm', // 28px
  'md': 'button-icon-md', // 32px
  'lg': 'button-icon-lg', // 36px

}

export const shape: Record<ButtonIconShape, string> = {
  none: '',
  square: 'button-icon-square',
  circle: 'button-icon-circle',
}

export { iconVariants }
