import { setAdGroupPublishers } from '../utils/adGroupUtils'
(function () {
    'use strict';

    angular.module('app')
        .controller('editAdGroupController', ['$scope', '$rootScope', '$uibModalInstance', '$sce', 'data', 'viewMode', 'hub', 'campaignService', 'creativeService', 'validationService', '$timeout', 'authService', '$location', 'logger', function ($scope, $rootScope, $modalInstance, $sce, data, viewMode, hub, campaignService, creativeService, validationService, $timeout, authService, $location, logger) {
        var vm = this;
        $scope.vm = vm;
        var log = hub.getLog('Ad Group Management');
        vm.log = log;
        vm.logger = logger;
        vm.rootVM = $rootScope.rootVM;

        vm.hasAccess = authService.hasAccess;
        vm.isAdmin = authService.isSuperAdmin();

        vm.canLaunch = false;
        vm.viewMode = viewMode;
        vm.now = new Date();
        vm.minDate = new Date();
        vm.minDate.setMinutes(vm.minDate.getMinutes() - 1);
        var adGroup = data.adGroup;
        vm.showFacebookErrors = showFacebookErrors;

        if (adGroup.budgetCap === 0 && adGroup.channelId != 9)
            adGroup.budgetCap = null;

        if (adGroup.inventoryLists === null)
            adGroup.inventoryLists = [];

        if (adGroup.startDate && !(adGroup.startDate.valueOf(Date) > 0)) {
            adGroup.startDate = new Date(adGroup.startDate);
        }

        if (adGroup.endDate && !(adGroup.endDate.valueOf(Date) > 0)) {
            adGroup.endDate = new Date(adGroup.endDate);
        }

        if (adGroup.startDate && (adGroup.startDate.valueOf(Date) > 0)) {
            vm.startDateInThePast = adGroup.startDate < vm.now;
            if (vm.startDateInThePast) {
                vm.minDate = adGroup.startDate;
            }
        }

        function getPublishers() {
            hub.webapicontext.getPublishers().then(function (publishers) {
                vm.publishers = publishers;
                vm.publishers.forEach(function (pub) {
                    var pubFound = vm.adGroup.publishers.find(p => p.id === pub.id);
                    if (pubFound) {
                        pub.selected = true;
                        pub.excluded = pubFound.excluded;
                        pubFound.name = pub.name;
                    }
                    pub.placements.forEach(function (place) {
                        var placeFound = vm.adGroup.monetizationPlacements.find(p => p.id === place.id);
                        if (placeFound) {
                            place.selected = true;
                            place.excluded = placeFound.excluded;
                            placeFound.name = place.name;
                            pub.open = true;
                        }
                        place.adUnits.forEach(function (adUnit) {
                          const adUnitFound = vm.adGroup.adUnits?.find(a => a.id === adUnit.id);
                          if (adUnitFound) {
                            adUnitFound.name = adUnit.name;
                            adUnit.selected = true;
                            adUnit.excluded = adUnitFound.excluded;
                            place.open = true;
                          }
                        });
                    });
                });
            });
        }

        // Automatically handles the isInProgress flag, including in case of errors
        async function inProgressWrapper(promise) {
            try {
                vm.isInProgress = true;
                const result = await promise
                vm.isInProgress = false;
                return result;
            } catch(_) {
                vm.isInProgress = false;
            }
        }

        getPublishers();

        $scope.canSaveProgress = function () {
            var selectedCampaign = vm.campaign || campaignService.getSelectedCampaign();
            var adGroupFormValid = false;
            if (typeof this.$parent.adGroupForm !== 'undefined') {
                adGroupFormValid = validationService.validateAdGroup(vm.adGroup, selectedCampaign, false) && this.$parent.adGroupForm.$valid;
            }
            return adGroupFormValid;
        }

        $scope.canSubmit = function () {
            var selectedCampaign = vm.campaign || campaignService.getSelectedCampaign();

            var adGroupFormValid = false;
            if (typeof this.$parent.adGroupForm !== 'undefined') {
                adGroupFormValid = validationService.validateAdGroup(vm.adGroup, selectedCampaign, true) && this.$parent.adGroupForm.$valid;
            }
            vm.canLaunch = adGroupFormValid;
            return adGroupFormValid;
        }

        $scope.cancel = function () { }

        $scope.ok = function()
        {
            if (vm.adGroup.adGroupId && vm.adGroup.channelId === 9) {
                if (!vm.adGroup.budgetCap)
                    vm.adGroup.budgetCap = 0;
                hub.webapicontext.validateDOAGDates(vm.adGroup).then(function (res) {
                    if (res.result)
                        vm.logger.logError(res.result, null, '', true, true, 300);
                    else
                        $scope.proceed();
                });
            }
            else
                $scope.proceed();
        }

        $scope.proceed = function() {
            var adGroupFormValid = true;
            if (adGroupFormValid && typeof this.$parent.adGroupForm !== 'undefined') {
                adGroupFormValid = this.$parent.adGroupForm.$valid;
            } else if (adGroupFormValid && typeof this.adGroupForm !== 'undefined') {
                adGroupFormValid = this.adGroupForm.$valid;
            }

            if (adGroup.optimizationGoalTypeId === 3) {
                let cpaTestFailure = false;
                if(adGroup.optimizationConversionSegments.length != 1){
                    vm.logger.logError("Must choose one optimization conversion pixel.", null, '', true, true, 300);
                    cpaTestFailure = true;
                    return;
                }
                let foundOptimizationConversionSegment = false;
                adGroup.conversionSegments.forEach(c => {
                    if(c.id ==adGroup.optimizationConversionSegments[0].id){
                        foundOptimizationConversionSegment = true;
                    }
                });
                if(!foundOptimizationConversionSegment){
                    vm.logger.logError("Need matching conversion pixel for optimization pixel.", null, '', true, true, 30);
                    cpaTestFailure = true;
                    return;
                }
                adGroup.optimizationConversionSegments.forEach(o =>{
                    if(o.excluded){
                        vm.logger.logError("Negatively targeting an optimization conversion pixel is not allowed.", null, '', true, true, 30);
                        cpaTestFailure = true;
                        return;
                    }
                    adGroup.conversionSegments.forEach(c => {
                        if(o.id == c.id && c.excluded){
                            vm.logger.logError("Negatively targeting a conversion pixel is not allowed.", null, '', true, true, 30);
                            cpaTestFailure = true;
                            return;
                        }
                    });
                });

                if(adGroup.cpaGoalTypeId == 1 && adGroup.cpaModeId == 2 ){
                    // If Post-click & Post-view + Retargeting is selected, require an audience to be targeted with type=Web
                    if(adGroup.audienceSegmentGroups.length < 1){
                        vm.logger.logError("Need at least one audience of type Web, Custom, Conversion or Retargeting is required..", null, '', true, true, 30);
                        cpaTestFailure = true;
                        return;
                    }
                    let found = false;
                    adGroup.audienceSegmentGroups.forEach(s => {
                        s.segments.forEach(a => {
                            if(["web", "custom", "retargeting", "conversion"].indexOf(a.audienceType.toLowerCase()) > -1) {
                                found = true;
                            }
                        });
                    });
                    if(!found){
                        vm.logger.logError("At least one audience of type Web, Custom, Conversion, or Retargeting is required.", null, '', true, true, 30);
                        cpaTestFailure = true;
                        return;
                    }
                }
                if(adGroup.cpaGoalTypeId == 2 && !(adGroup.maintainCPC > 0)){
                    // Post-click only
                    vm.logger.logError("Maintain CPC value must be greater than 0.", null, '', true, true, 30);
                    document.getElementById("maintainCPC").focus();
                    cpaTestFailure = true;
                    return;
                }
                if(cpaTestFailure){
                    return;
                }
            }

            var eventStartTime = new Date();

            if (vm.adGroup.isMeasurementOnly && vm.adGroup.channelId !== 9) {
                if (!adGroupFormValid || !vm.adGroup.creatives || vm.adGroup.creatives.length === 0 || !vm.adGroup.creatives[0].name) {
                    $('#adGroupForm').validator('destroy');
                    $('#adGroupForm').validator('validate');
                    vm.adGroup.validate = true;
                    adGroupFormValid = false;
                    return;
                }

                inProgressWrapper(hub.webapicontext.saveAdGroup(vm.adGroup, true).then(function (c) {
                    logEvent(vm.adGroup, eventStartTime);
                    $modalInstance.close(vm.adGroup);
                    $rootScope.$broadcast('campaignSelected', selectedCampaign);
                    vm.log(vm.adGroup.name + " Saved");
                }));
                return;
            }

            var selectedCampaign = vm.campaign || campaignService.getSelectedCampaign();

            if (adGroupFormValid) {
                adGroupFormValid = validationService.validateAdGroup(vm.adGroup, selectedCampaign, vm.adGroup.isLaunch) && !vm.adGroup.datesInvalid;
                if (!adGroupFormValid) {
                    if (!vm.adGroup.inBounds) {
                        const errorMessage = "The ad group is outside the campaign's flight bounds.";
                        vm.logger.logError(errorMessage, null, '', true, true);
                    } else if (vm.adGroup.flights.some(f => !f.inBounds)) {
                        const errorMessage = "One or more flights are outside the campaign's flight bounds.";
                        vm.logger.logError(errorMessage, null, '', true, true);
                    }
                }
            }

            if (!adGroupFormValid || vm.adGroup.endDateError || vm.adGroup.splitsInvalid) {
                vm.adGroup.validation = vm.adGroup.isLaunch || (vm.adGroup.adGroupStatusId != 0 && vm.adGroup.adGroupStatusId != 6);

                vm.adGroup.showAdvancedSettings = vm.adGroup.channelId != 9 && vm.adGroup.recencyValue < 1;

                $timeout(function () {
                    $('#adGroupForm').validator('destroy');
                    $('#adGroupForm').validator('validate');
                    $('#audienceForm').validator('destroy');
                    $('#audienceForm').validator('validate');
                    $('#adGroupFlights').trigger('input');
                    $('#dateRangeValidation').trigger('input');
                    $('#startDateValidation').trigger('input');
                    $('#endDateValidation').trigger('input');
                    $('#impsOverLifetime').trigger('input');
                    $('#impsPerUserPerHour').trigger('input');
                    $('#impsPerUserPerDay').trigger('input');
                    $('#impsPerUserPerWeek').trigger('input');
                    $('#optimizationGoal').trigger('input');
                    $('#optimizationGoalTypeId').trigger('input');
                    $('#spendBudgetCap').trigger('input');
                    $('#impressionsBudgetCap').trigger('input');
                    if (vm.adGroup.channelId == 6 || vm.adGroup.channelId == 16) {
                        $('#creativesValidation').trigger('input');
                    }
                    if (vm.adGroup.endDateError) {
                        vm.showFacebookErrors();
                    }
                    if (vm.adGroup.budgetCapSpendModeId == 2) {
                        $('#minBudgetError').hide();
                    }
                    if (vm.adGroup.maxMediaBid > 0 && vm.adGroup.maxMediaBid <= vm.maxBidCPM) {
                        document.getElementById("maxMediaBidTxt").style.display = "none";
                    }
                    if (vm.adGroup.audienceSelectionError) {
                        $('#audienceSelectionError').show();
                    }

                    $timeout(function () {
                        $('#audienceName').trigger('input');
                        $('#categoriesCountValidation').trigger('input');
                    });
                });
                return;
            }

            var saveOption = $("input[name=agSaveOptions]:checked").val();
            vm.adGroup.updateAllSegments = saveOption == 2;

            _.forEach(vm.adGroup.creatives, function (c) {
                if (c.provisionalAuditData)
                {
                    creativeService.setCreativeAuditData(c);
                }
            });

            if (!vm.canLaunch)
                vm.adGroup.submitForApproval = false;
            else
            {
                var creativeApprovalOption = $("input[name=crApproveOptions]:checked").val();
                vm.adGroup.submitForApproval = creativeApprovalOption == 1;
            }

            var recencyType = _.find(vm.rootVM.recencyTypes, function (rt) { return vm.adGroup.recencyType == rt.name; });
            if (recencyType)
                vm.adGroup.recencyTypeId = recencyType.id;
            else
                vm.adGroup.recencyTypeId = 0;

            var marketer = _.find(campaignService.marketers, function (r) { return vm.adGroup.marketerId == r.id; });
            if (marketer)
            {
                vm.adGroup.marketerId = marketer.id;

                if (marketer.taxonomyId && !vm.adGroup.id)
                    vm.adGroup.taxonomyId = marketer.taxonomyId;
            }

            if (vm.adGroup.optimizationGoalTypeId !== 3)
                vm.adGroup.optimizationConversionSegments = [];

            if (vm.adGroup.channelId == 16 || vm.adGroup.channelId == 25) {
                setAdGroupPublishers(vm.adGroup, vm.publishers);
            }

            vm.adGroup.audienceSegmentGroupsJSON = vm.adGroup.audienceSegmentGroups ? JSON.stringify(vm.adGroup.audienceSegmentGroups) : null;
            vm.adGroup.brandSafetySegmentsJSON = vm.adGroup.brandSafetySegments ? JSON.stringify(vm.adGroup.brandSafetySegments) : null;
            vm.adGroup.connectedTVSegmentsJSON = vm.adGroup.connectedTVSegments ? JSON.stringify(vm.adGroup.connectedTVSegments) : null;
            vm.adGroup.locationSegmentsJSON = vm.adGroup.locationSegments ? JSON.stringify(vm.adGroup.locationSegments) : null;
            vm.adGroup.conversionSegmentsJSON = vm.adGroup.conversionSegments ? JSON.stringify(vm.adGroup.conversionSegments) : null;
            vm.adGroup.optimizationConversionSegmentsJSON = vm.adGroup.optimizationConversionSegments ? JSON.stringify(vm.adGroup.optimizationConversionSegments) : null;
            vm.adGroup.publishersJSON = vm.adGroup.publishers ? JSON.stringify(vm.adGroup.publishers) : null;
            vm.adGroup.monetizationPlacementsJSON = vm.adGroup.monetizationPlacements ? JSON.stringify(vm.adGroup.monetizationPlacements) : null;
            vm.adGroup.adUnitsJSON = vm.adGroup.adUnits ? JSON.stringify(vm.adGroup.adUnits) : null;
            vm.adGroup.channelIds = _.map(vm.adGroup.channels, 'id').toString();
            vm.adGroup.emailDomains = _.map(vm.adGroup.emailDomainNames, 'value').join();

            if (vm.adGroup.conditions) {
                vm.adGroup.conditions.forEach(function (c) {
                    c.$$hashKey = undefined;
                });
                vm.adGroup.conditionsJSON = JSON.stringify(vm.adGroup.conditions);
            }

            vm.adGroup.schedule = vm.adGroup.scheduleModel ? JSON.stringify(vm.adGroup.scheduleModel) : '{}';

            selectedCampaign = campaignService.getSelectedCampaign();
            vm.adGroup.advertiserId = selectedCampaign.advertiserId;
            vm.adGroup.marketerId = selectedCampaign.marketerId;
            if (!vm.adGroup.potentialReach)
                vm.adGroup.potentialReach = 0;

            if (vm.adGroup.dayparting == 1)
                vm.adGroup.schedule = "{}";

            if (vm.adGroup.isPassedAdGroup)
                inProgressWrapper(hub.webapicontext.saveReportingAdGroup(vm.adGroup).then(function (adGroup) {
                    vm.adGroup = adGroup;
                    campaignService.setSelectedAdGroup(vm.adGroup, selectedCampaign);
                    logEvent(vm.adGroup, eventStartTime);
                    $modalInstance.close(vm.adGroup);
                    $rootScope.$broadcast('campaignSelected', selectedCampaign);
                    vm.log(vm.adGroup.name + " Saved");
                }));
            else {
                let getRoundedDate = (minutes, d = new Date()) => {
                    let ms = 1000 * 60 * minutes;
                    let roundedDate = new Date(Math.floor(d.getTime() / ms) * ms);
                    return roundedDate;
                }

                if (vm.adGroup.flights) {
                    vm.adGroup.flights.forEach(function (f) {
                        f.isLifetimePacing = vm.adGroup.budgetCapSpendModeId == 2;
                        if (!f.id) {
                            f.startDate = getRoundedDate(1, f.startDate);
                            if (f.endDate) {
                                f.endDate = getRoundedDate(1, f.endDate);
                                f.endDate.setSeconds(59);
                            }
                        }
                        // Start date time in the past criteria Start Time <= NOW - 10 min
                        f.startDateIsInThePast = f.startDate !== undefined && f.startDate !== null && (new Date(f.startDate).getTime() <= (vm.now.getTime() + 10 * 60 * 1000));
                        // End date time in the past criteria End Time < NOW
                        f.endDateIsInThePast = f.endDate !== undefined && f.endDate !== null && new Date(f.endDate).getTime() < vm.now.getTime();
                    });
                }

                if (!vm.adGroup.adGroupId) {
                    vm.adGroup.startDate = getRoundedDate(1, vm.adGroup.startDate);
                }

                if (vm.adGroup.endDate) {
                    vm.adGroup.endDate = getRoundedDate(1, vm.adGroup.endDate);
                    vm.adGroup.endDate.setSeconds(59);
                }
                if (vm.adGroup.channelId == 3 || vm.adGroup.channelId == 6 || vm.adGroup.budgetCapSpendModeId != 2) {
                    vm.adGroup.flights[0].startDate = vm.adGroup.startDate;
                    vm.adGroup.flights[0].endDate = vm.adGroup.endDate;
                }

                inProgressWrapper(hub.webapicontext.saveAdGroup(vm.adGroup).then(function (adGroup) {
                    adGroup.marketerId = vm.campaign.marketerId;
                    vm.adGroup = adGroup;

                    if (vm.adGroup.maxCPM != vm.maxCPM && vm.adGroup.adGroupId > 0) {
                        $rootScope.$broadcast('maxBidUpdated', vm.adGroup);
                    }

                    campaignService.setSelectedAdGroup(vm.adGroup, selectedCampaign);

                    logEvent(vm.adGroup, eventStartTime);

                    $modalInstance.close(vm.adGroup);
                    $rootScope.$broadcast('campaignSelected', selectedCampaign);
                    vm.log(vm.adGroup.name + " Saved");
                }));
            }
        };

        $scope.updateHiddenOptions = function () {
            var eventStartTime = new Date();
            var selectedCampaign = campaignService.getSelectedCampaign();
            inProgressWrapper(hub.webapicontext.updateadGroupHiddenOptions(vm.adGroup).then(function (adGroup) {
                vm.adGroup = adGroup;
                campaignService.getCampaignsStatistic(true).then(function () {
                    $rootScope.$broadcast('campaignsLoaded', null);
                });
                campaignService.setSelectedAdGroup(vm.adGroup, selectedCampaign);

                logEvent(vm.adGroup, eventStartTime);

                $modalInstance.close(vm.adGroup);
                $rootScope.$broadcast('campaignSelected', selectedCampaign);
                vm.log(vm.adGroup.name + " Saved");
            }));
        };

        function logEvent(entity, startTime) {
            var data = { id: entity.id, name: entity.name };
            authService.trackEvent({ action: 'SaveAdGroup', view: $location.$$path, data: JSON.stringify(data) }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
        }

        $scope.cancel = function (cancelClicked) {
            vm.cancelClicked = cancelClicked;
            $modalInstance.dismiss('cancel');
        };

        $scope.pauseRestartAdGroup = function () {
            if (vm.adGroup.adGroupStatusId == 10) {
                hub.webapicontext.restartAdGroup(vm.adGroup.adGroupId).then(function () {
                    vm.adGroup.adGroupStatusId = 1;
                    campaignService.setSelectedAdGroup(vm.adGroup);
                    $modalInstance.close(vm.adGroup);
                    vm.log(vm.adGroup.name + " Restarted");
                    campaignService.getCampaignsStatistic(true).then(function () {
                        $rootScope.$broadcast('campaignsLoaded', null);
                    });
                });
            } else {
                hub.webapicontext.pauseAdGroup(vm.adGroup.adGroupId).then(function () {
                    vm.adGroup.adGroupStatusId = 10;
                    campaignService.setSelectedAdGroup(vm.adGroup);
                    $modalInstance.close(vm.adGroup);
                    vm.log(vm.adGroup.name + " Paused");
                    campaignService.getCampaignsStatistic(true).then(function () {
                        $rootScope.$broadcast('campaignsLoaded', null);
                    });
                });
            }
        };

        function showFacebookErrors() {
            $('#weeklyBudget').addClass('has-error has-danger');
            $('#minBudgetError').show();
            $('#startDate').addClass('has-error has-danger');
            $('#endDate').addClass('has-error has-danger');
        }

        vm.adGroup = data.adGroup;
        vm.campaign = campaignService.getSelectedCampaign();
        if (vm.campaign) {
            vm.campaign.flights = []; // no flights by default
            campaignService.getCampaignFlights(vm.campaign.campaignId).then(flights => {
                vm.campaign.flights = flights;
                validationService.validateAdGroup(vm.adGroup, vm.campaign, false);
            });
        }

        vm.maxCPM = vm.adGroup.maxCPM;

        $scope.$on('modal.closing', function (event, reason) {
            if (!vm.showCaution && reason == 'cancel' && event.currentScope.adGroupForm.$dirty && vm.cancelClicked) {
                $timeout(function () {
                    vm.showCaution = true;
                });
                event.preventDefault();
            }
        });

        $scope.$on('modal.force.close', function () {
            $modalInstance.dismiss('cancel');
        });
        var legacySaveMessage = "<div class='radio'><label><input type='radio' name='agSaveOptions' id='agSaveOptionsDefault' value='1' checked>Change only for segments at $" + vm.adGroup.maxCPM + " CPM Rate (current default CPM Rate)</label></div><div class='radio'><label><input type='radio' name='agSaveOptions' id='agSaveOptionsCustom' value='2'>Change for ALL segments</label></div>";
        var newFinSaveMessage = "<div class='radio'><label><input type='radio' name='agSaveOptions' id='agSaveOptionsDefault' value='2' checked>Update ALL segments to new Max Media Bid</label></div><div class='radio'><label><input type='radio' name='agSaveOptions' id='agSaveOptionsCustom' value='1'>Only segments in excess of Max Media Bid (Calculated) will be reduced</label></div>";
        $scope.saveMessage = vm.adGroup.isLegacy ? legacySaveMessage : newFinSaveMessage;
        $scope.isMaxBidChanged = function () {
            var baseCondition = vm.adGroup.adGroupId > 0 && vm.adGroup.channelId != 3;
            if(baseCondition){
                if(vm.adGroup.isLegacy) {
                    return baseCondition && vm.adGroup.maxCPM != vm.maxCPM;
                } else {
                    return baseCondition && (vm.maxBidCPM != vm.originalMaxBidCPM || vm.adGroup.originalMaxMediaBid != vm.adGroup.maxMediaBid);
                }
            }
            return false;
        }
        $scope.creativeApproveMessage = "<div class='radio'><label><input type='radio' name='crApproveOptions' id='crApproveOptionsDefault' value='1' checked>Submit Creative for approval</label></div><div class='radio'><label><input type='radio' name='crApproveOptions' id='crApproveOptionsCustom' value='2'>Save Creative</label></div>";
        if(vm.adGroup.adGroupStatusId == 15){
            vm.isArchived = true;
        }
    }]);

})();
