<template>
  <Box>
    <FormControl :key="String(isDataOnly)" v-slot="{ error: hasError }" :errorText="audiencesError" hideLabel>
      <DropdownList
        ref="dropdownRef"
        trigger="manual"
        :width="`${dropdownWidth}px`"
        @clickOutside="hideDropdown"
      >
        <template #default="{ show }">
          <InputText
            :id="getId('audience.audiences')"
            v-model="searchQueryDebounced"
            placeholder="Search for an audience"
            icon="search"
            class="select-input-wrapper"
            :error="hasError"
            autocomplete="off"
            @focus="show"
            @click="show"
          />
        </template>

        <template #dropdown="{ hide }">
          <Paper disablePadding>
            <ErrorState v-if="error" :error="error" @retry="request()" />
            <template v-else>
              <AudienceSearchList
                :audiences="audiences"
                :selectedIds="selectedAudiencesIds"
                :loading="fetching"
                :searchQuery="searchQuery"
                @select="selectAudience($event, hide)"
              />
              <InfiniteScroll
                v-if="!fetching"
                :disabled="!hasNextPage"
                :fetchNextPage="requestNextPage"
              />
            </template>
          </Paper>
        </template>
      </DropdownList>
    </FormControl>

    <!--
    NOTE: Types selector is not requested for the current MVP implementation, but highly likely will be requested in the next iterations As well, this tabs list contains a valid subset of Audiences types that can be used for the AdGroupAudience section.
    <Tabs v-model:active="searchType" variant="text" size="md">
      <TabsItem value="none">
        All
      </TabsItem>
      <TabsItem :value="AudienceType.PRESCRIBER">
        Prescriber
      </TabsItem>
      <TabsItem :value="AudienceType.CONSUMER">
        Consumer
      </TabsItem>
      <TabsItem :value="AudienceType.PROFESSIONAL">
        Professional
      </TabsItem>
      <TabsItem :value="AudienceType.DEMOGRAPHICS">
        Demographics
      </TabsItem>
      <TabsItem :value="AudienceType.IBM_WEATHER">
        Weather
      </TabsItem>
      <TabsItem :value="AudienceType.CUSTOM">
        Custom
      </TabsItem>
      <TabsItem :value="AudienceType.RETARGETING">
        Retargeting
      </TabsItem>
    </Tabs>
    -->
  </Box>
</template>

<script setup lang="ts">
import { ComponentPublicInstance, computed, ref } from 'vue'
import { AudienceType, ChannelType } from '@lasso/api-activation/activation'
import {
  Box,
  DropdownList,
  ErrorState, FormControl,
  InfiniteScroll,
  InputText,
  Paper,
} from '@lasso/luikit'
import { useApiInfinite } from '@lasso/shared/hooks'
import { reversedDebouncedRef } from '@lasso/shared/utils'
import { camelCase } from 'lodash-es'
import { useElementSize } from '@vueuse/core'

import {
  channelActApiToLegacy,
  useActivationLegacyApi,
} from '@lasso/api-activation/activation/legacy'

import { AdGroupAudience } from '../types'
import { useCardAudiences } from '../useCardAudiences'

import { AudienceTargetId } from '../consts'

import AudienceSearchList from './AudienceSearchList.vue'

const props = withDefaults(defineProps<{
  selectedAudiences: AdGroupAudience[]
  hideOnClick: boolean
}>(), {
  selectedAudiences: () => [],
})

const emits = defineEmits<{
  'select': [AdGroupAudience]
}>()

const legacyApi = useActivationLegacyApi()

const { marketerId, adGroupAccountId, channelId, isDataOnly, audiencesError, getId, isDirectSold } = useCardAudiences()!

const searchQuery = ref('')
const searchQueryDebounced = reversedDebouncedRef(searchQuery, 150)
const searchQueryPrepared = computed(() => searchQuery.value.trim())
const searchType = ref<AudienceType | 'none'>('none')
const dropdownRef = ref<ComponentPublicInstance & { hide: () => void } | null>(null)

const { width: dropdownWidth } = useElementSize(dropdownRef)

const selectedAudiencesIds = computed(() => props.selectedAudiences.map(item => item.id))

const audienceTarget = computed(() => {
  switch (channelId.value) {
    case ChannelType.ENDEMIC_DTC:
      return AudienceTargetId.PatientConsumer
    case ChannelType.ENDEMIC_HCP:
    case ChannelType.ENDEMIC_EHR:
      return AudienceTargetId.HealthcareProvider
    default:
      return isDirectSold ? AudienceTargetId.HealthcareProvider : undefined
  }
})

const hideDropdown = () => {
  dropdownRef.value?.hide()
}

const { data, requestNextPage, hasNextPage, fetching, error, request } = useApiInfinite(
  legacyApi.audiences.getAudiences,
  (page) => {
    return [{
      keyword: searchQueryPrepared.value,
      marketerId: marketerId.value,
      accountId: adGroupAccountId.value,
      adGroupChannelId: channelActApiToLegacy[channelId.value],
      pageNumber: page.param,
      pageSize: page.size,
      audienceType: searchType.value === 'none' ? undefined : searchType.value,
      audienceTarget: audienceTarget.value,
      sortName: searchQueryPrepared.value ? undefined : 'CreatedDate',
    }]
  },
  {
    getNextPageParam: ({ responseData, curPage, requestData: _requestData }) => {
      const requestData = _requestData?.[0]

      if (requestData?.keyword === '' && curPage.param === 1) {
        return null
      }

      return responseData.total > curPage.param * responseData.pageSize ? curPage.param + 1 : null
    },
    initialPageParam: 1,
    pageSize: computed(() => searchQueryPrepared.value ? 20 : 5),
    getDataArray: data => data.list,
    refetch: true,
    cache: true,
  },
)

const audiences = computed(() => data.value.map((item): AdGroupAudience => {
  return {
    id: item.id,
    name: item.name,
    audienceType: camelCase(item.audienceType) as AudienceType,
    matchedDeviceRecords: item.matchedDeviceRecords || 0,
    displayCpm: item.displayCpm || 0,
    socialCpm: item.socialCpm || 0,
    createdDate: item.createdDate,
    audienceTargetId: item.audienceTargetId ? String(item.audienceTargetId) : '',
    groupId: 0,
    hasSegmentationFilter: item.hasSegmentationFilter,
  }
}))

const selectAudience = (audience: AdGroupAudience, hide: () => void) => {
  emits('select', audience)
  if (props.hideOnClick) {
    hide()
  }
}
</script>
