import { ToastErrorFormatter, useProvideToast } from '@lasso/shared/hooks'

import Toast from './Toast.vue'
import { ToastContainer } from './ToastContainer'

export const useInitToast = ({ formatError }: { formatError: ToastErrorFormatter }) => {
  return useProvideToast({
    parentContainer: document.body,
    createContainer: () => {
      const container = document.createElement('div')
      container.classList.add('v-toast--pending')
      return container
    },
    toastContainer: ToastContainer,
    toastComponent: Toast,
    toastProps: {
      title: '',
      message: '',
      color: 'base',
      actions: [],
    },
    position: 'bottom-right',
    duration: 5000,
    dismissible: true,
    queue: false,
    pauseOnHover: true,
    target: '',
    zIndex: 999999,
    formatError,
  })
}
