import { BaseSchema, InferType, ObjectSchema, ValidationError, object } from 'yup'
import {
  NavigationFailureType,
  isNavigationFailure,
  onBeforeRouteLeave,
  useRoute,
  useRouter,
} from 'vue-router'
import { Ref, computed, nextTick, shallowRef } from 'vue'
import { syncRef } from '@vueuse/core'
import { isEmpty } from 'lodash-es'

import { objEntries, optimizedComputedRef, removeDefaults, stringifyObjectKeys, toSingleRef } from '../utils'
import { KeyOf } from '../types'

import { useMutableWatchers } from './useMutableWatchers'
import { useGlobalState } from './useGlobalState'

type PendingQuery = {
  query: Record<string, unknown>
  defaults: Record<string, unknown>
}

const useQueryQueue = () => {
  const queueState = useGlobalState('useQueryQueue', () => ({
    queue: shallowRef<PendingQuery[]>([]),
    isProcessing: false,
    retryCount: 3,
  }), true)

  const route = useRoute()
  const router = useRouter()

  const queryWithQueue = computed(() => {
    const queue = queueState.value.queue.value

    const finalQuery = queue.reduce<Record<string, unknown>>((finalQuery, { query, defaults }) => {
      return removeDefaults({ ...finalQuery, ...query }, defaults)
    }, { ...route.query })

    return finalQuery
  })

  const commitQueries = async () => {
    queueState.value.isProcessing = true

    // Batch updates made in the same tick
    await nextTick()

    if (queueState.value.queue.value.length === 0) {
      queueState.value.isProcessing = false
      return
    }

    const length = queueState.value.queue.value.length
    const finalQuery = stringifyObjectKeys(queryWithQueue.value)

    const error = await router.replace({ query: finalQuery })

    // Queue was cleared during navigation, don't retry
    if (queueState.value.queue.value.length === 0) {
      queueState.value.retryCount = 3
      queueState.value.isProcessing = false
      return
    }

    const isCancel = isNavigationFailure(error, NavigationFailureType.cancelled)

    /*
     * If navigation was canceled, retry it
     * TL;DR: single-spa bad
     * This is needed because of a race condition between vue-router and single-spa
     * single-spa emits a fake popstate event on navigation
     * https://github.com/single-spa/single-spa/blob/v6.0.1/src/navigation/navigation-events.js#L95
     * which vue-router picks up
     * https://github.com/vuejs/router/blob/v4.1.6/packages/router/src/router.ts#L983
     * and cancels the later navigation
     * https://github.com/vuejs/router/blob/v4.1.6/packages/router/src/router.ts#L576
     * Because this is all done async, there isn't a robust way to wait for the fake popstate event to be handled
     * before doing a new navigation. This also only happens only when the new navigation is done not immediately,
     * but soon after the first one.
     * The retryCount is a safeguard against potential infinite loops
     */
    /* v8 ignore next 3 */
    if (isCancel && queueState.value.retryCount > 0) {
      queueState.value.retryCount -= 1
    }
    else {
      queueState.value.retryCount = 3
      queueState.value.queue.value = queueState.value.queue.value.slice(length)
    }

    if (queueState.value.queue.value.length > 0) {
      await commitQueries()
    }
    else {
      queueState.value.isProcessing = false
    }
  }

  const addQueryToQueue = (pendingQuery: PendingQuery) => {
    queueState.value.queue.value = [...queueState.value.queue.value, pendingQuery]

    if (!queueState.value.isProcessing) {
      void commitQueries()
    }
  }

  const clearQueryQueue = () => {
    queueState.value.queue.value = []
  }

  return {
    queryWithQueue,
    addQueryToQueue,
    clearQueryQueue,
  }
}

/**
 * Allows storing arbitrary data in the route query.
 * Nested objects are stored in the query as JSON and parsed by yup.
 * Uses router.replace when query changes.
 * TODO: if there's a use case for using router.push instead, add an option here.
 *
 * @example
 * // yup schema
 * const querySchema = object({
 *   tab: number().default(0),
 *   order: string().default('DESC')
 * })
 *
 * const { query } = useQuery(querySchema)
 *
 * // output: { tab: 0, sort: 'REV' }
 * console.log(query.value)
 * // output: {}
 * console.log(useRoute().query)
 *
 * query.value = { tab: 1, order: 'ASC' }
 *
 * // output: { tab: 1, order: 'ASC' }
 * console.log(useRoute().query)
 *
 * @example multiple views on the same query (make sure that queries don't conflict with each other)
 * const { query: query1 } = setupTest(
 *    object({
 *      view1: object({
 *        string: string().default('string1'),
 *      }),
 *    }),
 *  )
 *
 *  const { query: query2 } = useQuery(
 *    object({
 *      view2: object({
 *        string: string().default('string2'),
 *      }),
 *    }),
 *  )
 */
export const useQuery = <T extends ObjectSchema<any, any, any, any>>(schema: T): {
  query: Ref<InferType<T>>
  defaults: InferType<T>
  isQuerySet: (key: KeyOf<InferType<T>>) => boolean
  syncQuery: (otherRefs: { [K in KeyOf<InferType<T>>]?: Ref<InferType<T>[K]> }) => void
} => {
  const defaults = schema.cast({})
  const route = useRoute()
  const {
    queryWithQueue,
    addQueryToQueue,
    clearQueryQueue,
  } = useQueryQueue()
  const { addWatchers: addSyncRefWatchers, unwatch: unwatchSyncedRefs } = useMutableWatchers()

  onBeforeRouteLeave((to, from) => {
    if (to.path !== from.path) {
      // Ensure queries from other pages don't overwrite synced refs
      unwatchSyncedRefs()
      // Ensure we don't overwrite navigations to other pages
      clearQueryQueue()
    }
  })

  const query = optimizedComputedRef({
    get: () => {
      try {
        return schema.validateSync(queryWithQueue.value, { stripUnknown: true })
      }
      catch (error) {
        /* v8 ignore next 3 */
        if (!(error instanceof ValidationError)) {
          throw error
        }

        return defaults
      }
    },
    set: (query) => {
      addQueryToQueue({ query, defaults })
    },
  })

  const isQuerySet = (key: KeyOf<InferType<T>>): boolean => {
    // We check current route directly in case the default value was set explicitly
    return Boolean(route.query[key] || queryWithQueue.value[key])
  }

  /**
   * Synchronizes parts of the query with other refs.
   * The direction of initial sync depends on whether the query is set in the url.
   * If it is - it takes priority, otherwise the other ref does.
   *
   * @example store the page size in both the url and local storage
   * const { query, defaults, syncQuery } = useQuery(object({
   *   pageSize: oneOfEnum([10, 25, 50, 100]).default(10),
   * }))
   * syncQuery({
   *   pageSize: useUserLocalStorage('pageSize', defaults.pageSize),
   * })
   */
  const syncQuery = (otherRefs: { [K in KeyOf<InferType<T>>]?: Ref<InferType<T>[K]> }) => {
    const queryToSet: Partial<T> = {}

    objEntries(otherRefs).forEach(([key, otherRef]) => {
      const queryRef = toSingleRef(query, key)

      addSyncRefWatchers([
        syncRef(queryRef as Ref<unknown>, otherRef as Ref<unknown>, { immediate: false, flush: 'post' }),
      ])

      if (isQuerySet(key)) {
        otherRef.value = queryRef.value
      }
      else {
        queryToSet[key] = otherRef.value
      }
    })

    if (!isEmpty(queryToSet)) {
      query.value = {
        ...query.value,
        ...queryToSet,
      }
    }
  }

  return { query, defaults, isQuerySet, syncQuery }
}

/** {@link useQuery} for a single query key. */
export const useQueryKey = <T extends BaseSchema>(key: string, schema: T): {
  query: Ref<InferType<T>>
  defaultValue: InferType<T>
  isKeySet: Readonly<Ref<boolean>>
  syncQueryKey: (otherRef: Ref<InferType<T>>) => void
} => {
  const { query, defaults, isQuerySet, syncQuery } = useQuery(object({ [key]: schema }))
  const isKeySet = computed(() => isQuerySet(key))
  const syncQueryKey = (otherRef: Ref<InferType<T>>) => {
    syncQuery({ [key]: otherRef })
  }

  return {
    query: toSingleRef(query, key),
    defaultValue: defaults[key] as T,
    isKeySet,
    syncQueryKey,
  }
}
