/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ReportingConnectionType {
    SFTP = 'sftp',
    S3 = 's3',
    AZURE = 'azure',
    EMAIL = 'email',
}
