import { computed, ref, watch } from 'vue'
import { useTimeoutFn } from '@vueuse/core'

import { useAuth } from './useAuth'

export const useAuthTokenExpiration = () => {
  const { accessToken, logout } = useAuth()

  const expiresAt = computed(() => accessToken.value?.exp ?? null)
  const timeout = ref(0)

  const { start, stop } = useTimeoutFn(() => logout(true), timeout, { immediate: false })

  watch(expiresAt, () => {
    if (expiresAt.value === null) {
      timeout.value = 0
      stop()
    }
    else {
      timeout.value = Math.max((expiresAt.value * 1000) - Date.now(), 0)
      start()
    }
  }, { immediate: true })
}
