import { CreativeShortInfo, CreativeStatus, EndemicFormatType } from '@lasso/api-activation/activation'
import { objGroupBy, objValues } from '@lasso/shared/utils'

import { AdGroupCreative, getBannerSize } from '../../../shared/creatives'

export const buildCreative = (creative: CreativeShortInfo): AdGroupCreative => {
  return {
    ...creative,
    id: creative.id!,
    name: creative.name ?? '',
    weight: creative.weight || 1,
    creativeStatus: creative.creativeStatus ?? CreativeStatus.DRAFT,
  }
}

export const getCreativesTotalWeight = (creatives: AdGroupCreative[]): number => {
  return creatives.reduce((acc, creative) => acc + (creative.weight ?? 0), 0)
}

export const groupCreatives = (creatives: AdGroupCreative[]): AdGroupCreative[][] => {
  return objValues(objGroupBy(creatives, creative => getBannerSize(creative)))
}

export const shouldHaveStaticIndication = (creative: AdGroupCreative, endemicFormatId: EndemicFormatType | null) => {
  return !creative.thirdPartyServed && endemicFormatId === EndemicFormatType.DISPLAY_AND_ENEWSLETTER
}
