import { onBeforeUnmount, onMounted, toValue } from 'vue'

import { RefOrGetter } from '../../types'
import { useGlobalState } from '../useGlobalState'
import { useUser } from '../useUser'

export const useExternalNavigationGuard = (shouldGuard: RefOrGetter<boolean>) => {
  const guardState = useGlobalState('externalNavigationGuard', () => ({
    guardCounter: 0,
    guardChecks: [] as Array<RefOrGetter<boolean>>,
    guardExternal: (event: Event): void => {
      /* v8 ignore next 3 */
      if (process.env.COMMAND === 'serve') {
        return
      }

      if (guardState.value.guardChecks.some(check => toValue(check))) {
        event.preventDefault()
        event.returnValue = true
      }
    },
  }), true)

  const { isLoggedIn } = useUser()

  onMounted(() => {
    const { guardChecks, guardExternal } = guardState.value
    guardChecks.push(() => toValue(shouldGuard) && isLoggedIn.value)

    if (guardState.value.guardCounter === 0) {
      window.addEventListener('beforeunload', guardExternal)
    }

    guardState.value.guardCounter += 1
  })

  onBeforeUnmount(() => {
    const { guardChecks, guardExternal } = guardState.value
    guardState.value.guardChecks = guardChecks.filter(check => check !== shouldGuard)
    guardState.value.guardCounter = Math.max(guardState.value.guardCounter - 1, 0)

    if (guardState.value.guardCounter === 0) {
      window.removeEventListener('beforeunload', guardExternal)
    }
  })
}
