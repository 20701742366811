import { downloadData } from './downloadData'

const parseErrorMessage = async (error: string): Promise<string> => {
  const xml = new window.DOMParser().parseFromString(error, 'text/xml')
  const errorNode = xml.querySelector('parsererror')

  if (errorNode) {
    return error
  }

  const messageNode = xml.querySelector('Message')

  return messageNode?.innerHTML ?? error
}

export const downloadFile = async (
  url: string,
  filename: string,
): Promise<void> => {
  const response = await fetch(url).catch(error => error as Error)

  if (response instanceof Error) {
    throw response
  }

  if (!response.ok) {
    const message = await parseErrorMessage(await response.text())
    throw new Error(message, { cause: response })
  }

  downloadData(await response.blob(), filename)
}
