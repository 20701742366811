<template>
  <div :class="s.progressBarContainer">
    <div
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax="100"
      :aria-valuenow="progressWithConstraints"
      aria-label="Content loading..."
      :style="style"
      :class="[
        s.progressBar,
        s[color],
        { [s.indeterminate!]: indeterminate }]"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, useCssModule } from 'vue'

import { ProgressBarColor } from './types'

const { progress = 0, indeterminate = false, color = 'default' } = defineProps<{
  progress?: number
  color?: ProgressBarColor
  indeterminate?: boolean
}>()

const s = useCssModule()

const progressWithConstraints = computed(() => {
  return Math.max(Math.min(progress, 100), 0)
})

const style = computed(() => ({
  transform: `translateX(${progressWithConstraints.value}%)`,
}))
</script>

<style module>
.progressBarContainer {
  @apply flex relative bg-base-400 h-1.5 w-full overflow-hidden rounded-full;
}

.progressBar {
  @apply absolute w-full rounded-full top-0 bottom-0;
  translate: -100%;
  transform-origin: left;
  transition: 0.4s linear transform;
}

.indeterminate {
  translate: 0; /* Reset the translate */
  animation: 1.5s infinite indeterminateProgressBar;
}

@keyframes indeterminateProgressBar {
  0% {
    transform: translateX(-50%) scaleX(0);
  }
  75% {
    transform: translateX(25%) scaleX(0.5);
  }
  100% {
    transform: translateX(100%) scaleX(0.05);
  }
}

.default {
  @apply bg-base-600;
}

.primary {
  @apply bg-primary;
}

.secondary {
  @apply bg-secondary;
}

.accent {
  @apply bg-accent;
}

.info {
  @apply bg-info;
}

.success {
  @apply bg-success;
}

.warning {
  @apply bg-warning;
}

.error {
  @apply bg-error;
}
</style>
