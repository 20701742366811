import {
  Ref, computed,
  ref, shallowReactive, shallowReadonly, toValue,
} from 'vue'

import { objFromEntries } from '../../utils'

import { MultiStepFormStep } from './types'

export const useSteps = <K extends string, StepName extends string>(
  { steps, getAvailableSteps }: {
    steps: Array<MultiStepFormStep<K, StepName>>
    getAvailableSteps: () => StepName[]
  },
) => {
  const availableSteps = computed(() => {
    const availableStepNames = getAvailableSteps()

    return steps.filter(step => availableStepNames.includes(step.name))
  })
  const stepsByKey = objFromEntries(steps.map(step => [step.name, step]))

  const currentStep = ref(toValue(availableSteps)[0]!.name) as Ref<StepName>
  const visitedSteps = shallowReactive(new Set<StepName>())

  const isFirstStep = computed(() => {
    return toValue(availableSteps)[0]?.name === currentStep.value
  })

  const isLastStep = computed(() => {
    const _availableSteps = toValue(availableSteps)

    return _availableSteps[_availableSteps.length - 1]?.name === currentStep.value
  })

  const getAvailableStepIndex = (stepName: StepName) => {
    return toValue(availableSteps).findIndex(step => step.name === stepName)
  }

  const addStepToVisited = (stepName: StepName) => {
    visitedSteps.add(stepName)
  }

  const addStepsBeforeToVisited = (stepName: StepName) => {
    const stepIndex = getAvailableStepIndex(stepName)

    toValue(availableSteps).forEach((step, index) => {
      if (index < stepIndex) {
        visitedSteps.add(step.name)
      }
    })
  }

  const addAllStepsToVisited = () => {
    toValue(availableSteps).forEach((step) => {
      visitedSteps.add(step.name)
    })
  }

  const isStepVisited = (stepName: StepName) => {
    return visitedSteps.has(stepName)
  }

  const goToNextStep = () => {
    const curIndex = getAvailableStepIndex(currentStep.value)
    const _availableSteps = toValue(availableSteps)

    if (curIndex !== -1 && curIndex < _availableSteps.length - 1) {
      currentStep.value = _availableSteps[curIndex + 1]!.name
    }
  }

  const goToPrevStep = () => {
    const curIndex = getAvailableStepIndex(currentStep.value)
    const _availableSteps = toValue(availableSteps)

    if (curIndex !== -1 && curIndex > 0) {
      currentStep.value = _availableSteps[curIndex - 1]!.name
    }
  }

  const goToStep = (stepName: StepName) => {
    if (availableSteps.value.some(step => step.name === stepName)) {
      currentStep.value = stepName
    }
  }

  return {
    stepsByKey,
    availableSteps,
    currentStep: shallowReadonly(currentStep),
    isFirstStep,
    isLastStep,

    addStepToVisited,
    addStepsBeforeToVisited,
    addAllStepsToVisited,
    isStepVisited,
    goToNextStep,
    goToPrevStep,
    goToStep,
  }
}
