<template>
  <SelectPanel autoHeight>
    <template #search>
      <SelectPanelInput v-model="searchQuery" />
    </template>

    <template #items>
      <SelectPanelItem
        v-for="item in filteredItems"
        :key="item.id"
        :disabled="isItemDisabled(item.id!)"
        :modelValue="isItemSelected(item.id!)"
        :searchQuery="searchQuery"
        :label="item.name!"
        :chip="item.id"
        :info="`Sites: ${item.totalRecordsCount}`"
        @update:modelValue="handleListItemSelected(item)"
      />
    </template>

    <template #selectedItemsTitle>
      {{ selectedItemsLabel }}
    </template>

    <template v-if="selectedListItemsReverse.length > 0" #selectedItems>
      <SelectPanelSelectedItem
        v-for="item in selectedListItemsReverse"
        :key="item.id"
        :enabled="(item.inventoryListType) === InventoryListType.WHITELIST"
        :label="item.name"
        :chip="item.id"
        :info="`Sites: ${item.totalRecordsCount}`"
        :offColor="(item.inventoryListType) === InventoryListType.BLACKLIST ? 'error' : 'success'"
        noToggle
        @remove="emit('removeSelectedItem', item)"
      />
    </template>
  </SelectPanel>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

import { SelectPanel, SelectPanelInput, SelectPanelItem, SelectPanelSelectedItem } from '@lasso/luikit'
import { InventoryListShortInfo, InventoryListType } from '@lasso/api-activation/activation'

const props = withDefaults(defineProps<{
  listItems: InventoryListShortInfo[]
  selectedListItems: InventoryListShortInfo[]
  disableNotSelectedItems?: boolean
  selectedItemsLabel?: string
}>(), {
  selectedItemsLabel: 'Selected Items',
  disableNotSelectedItems: false,
})

const emit = defineEmits<{
  listItemSelected: [InventoryListShortInfo]
  removeSelectedItem: [InventoryListShortInfo]
}>()

const searchQuery = ref('')

const filteredItems = computed(() => {
  return props.listItems.filter((item) => {
    return item.name!.toLowerCase().includes(searchQuery.value.toLowerCase())
  })
})

const selectedListItemsReverse = computed(() => {
  return props.selectedListItems.slice().reverse()
})

const selectedItemsMap = computed(() => {
  return props.selectedListItems.reduce((acc, item) => {
    acc[item.id!] = item
    return acc
  }, {} as Record<number, InventoryListShortInfo>)
})

const isItemSelected = (id: number) => {
  return !!selectedItemsMap.value[id]
}

const isItemDisabled = (id: number) => {
  return props.disableNotSelectedItems && !isItemSelected(id)
}

const handleListItemSelected = (item: InventoryListShortInfo) => {
  if (!isItemDisabled(item.id!)) {
    emit('listItemSelected', item)
  }
}
</script>
