<template>
  <Card title="Creatives" opened collapse withDivider>
    <Box v-if="!loading && !error" mb="6">
      <CreativesSearch />
    </Box>
    <Skeleton v-if="loading" height="192px" />
    <ErrorState v-else-if="error" :error="error" @retry="request()" />
    <Box
      v-else-if="creatives.length === 0"
      minHeight="132px"
      flex
      col
      spaceY="4"
      alignItems="center"
      justify="center"
    >
      <Paper
        color="secondary-light"
        rounded="xl"
        flex
        justify="center"
        alignItems="center"
        height="48px"
        width="48px"
      >
        <Icon icon="search" size="lg" variant="outlined" color="secondary" />
      </Paper>
      <Typography variant="body2">
        Search for a creative to get started
      </Typography>
    </Box>
    <Table
      v-else
      :fields="tableColumns"
      :data="pageItems"
      hoverable
      stickyFirstColumn
      hideEmptyRow
    >
      <template #name="{ value, row: creative, hovered }">
        <TableCellName v-if="creative.isTotal">
          {{ value }}
        </TableCellName>
        <TableCellName
          v-else
          :hovered="hovered"
          :href="getCreativePreviewUrl(creative)"
          target="_blank"
          clickable
        >
          <template #chip>
            <Chip size="2xs" variant="outlined" :label="creative.id" />
            <Chip v-if="shouldHaveStaticIndication(creative, endemicFormatId)" size="2xs" variant="outlined" label="Static" />
          </template>
          {{ value }}
          <template #actions>
            <Tooltip v-if="getPreviewImageUrl(creative)" placement="top">
              <Link
                :to="getCreativePreviewUrl(creative)"
                target="_blank"
                class="flex"
              >
                <ButtonIcon icon="remove_red_eye" iconVariant="outlined" />
              </Link>
              <template #content>
                <img
                  :src="getPreviewImageUrl(creative)"
                  :width="getCreativePreviewSize(creative).width"
                  :alt="creative.name"
                >
              </template>
            </Tooltip>
            <ButtonIcon icon="delete_outline" @click="setCreativesSelected(creative, false)" />
          </template>
        </TableCellName>
      </template>

      <template #creativeStatus="{ rawValue, row: creative }">
        <Chip
          v-if="!creative.isTotal"
          size="2xs"
          variant="outlined"
          :color="creativeStatusChipMap[rawValue].color"
          :label="creativeStatusChipMap[rawValue].label"
        />
      </template>

      <template #bannerSize="{ row: creative }">
        <Typography variant="body2">
          {{ getBannerSize(creative) }}
        </Typography>
      </template>

      <template #createdDate="{ value, row: creative }">
        <Typography v-if="!creative.isTotal" variant="body2">
          {{ formatDate(value, 'longDateAbbr', 'EST') }}
        </Typography>
      </template>

      <template #weight="{ value, row: creative, active }">
        <InputText
          v-if="active && !creative.isTotal"
          :modelValue="creative.weight"
          :mask="numberMask"
          @update:modelValue="updateWeight(creative.id, $event)"
          @keydown.enter.prevent
        />
        <Typography v-else variant="body2" :color="getWeightColor(creative)">
          {{ value }}
        </Typography>
      </template>
    </Table>
    <Box v-if="visible.pagination" flex mt="2" justify="end">
      <Pagination v-model="pageNumber" :pagesTotal="pagesTotal" />
    </Box>

    <FormError :id="getId('creatives.totalAllocatedValidation')" pt="2" />
  </Card>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import {
  Box,
  ButtonIcon,
  Card,
  Chip,
  ErrorState,
  FormError,
  Icon, InputText,
  Link,
  Pagination,
  Paper,
  Skeleton,
  Table,
  TableCellName,
  TableColumns,
  TableColumnsSparse,
  Tooltip,
  Typography,
  useTableVisibility,
} from '@lasso/luikit'
import { CreativeRotationType, CreativeStatus } from '@lasso/api-activation/activation'
import { FormatEnum, formatDate, formatNumber, truthy } from '@lasso/shared/utils'
import { numberMask } from '@lasso/shared/consts'
import { usePaginatedArray } from '@lasso/shared/hooks'

import { AdGroupCreative, creativeStatusChipMap, getBannerSize } from '../../../../shared/creatives'
import { getCreativesTotalWeight, groupCreatives, shouldHaveStaticIndication } from '../utils'
import { useCardCreatives } from '../useCardCreatives'

import CreativesSearch from './CreativesSearch.vue'

type AdGroupCreativeGrouped = AdGroupCreative & {
  isTotal?: boolean
}

const {
  loading,
  error,
  rotationType,
  creatives,
  getId,
  request,
  endemicFormatId,
  setCreativesSelected,
  getCreativePreviewUrl,
} = useCardCreatives()!

const weightedRotation = computed(() => rotationType.value === CreativeRotationType.WEIGHTED)

const tableColumns = computed((): TableColumns<AdGroupCreativeGrouped> => {
  const columns: TableColumnsSparse<AdGroupCreativeGrouped> = [
    {
      id: 'name',
      label: `Creatives (${creatives.value.length})`,
      width: '467px',
    },
    {
      id: 'bannerSize',
      label: 'Ad Size',
      minWidth: '145px',
    },
    weightedRotation.value
      ? {
          id: 'weight',
          label: 'Distribution',
          width: '145px',
          modifier: val => formatNumber(+(val ?? 0) / 100, FormatEnum.Percent),
        }
      : null,
    {
      id: 'creativeStatus',
      label: 'Status',
      minWidth: '145px',
    },
    {
      id: 'createdDate',
      label: 'Created',
      minWidth: '145px',
    },
  ]

  return columns.filter(truthy)
})

const getCreativePreviewSize = (row: AdGroupCreativeGrouped) => {
  const [width = 0, height = 1] = getBannerSize(row).split('x').map(it => +it)

  const ratio = width / height

  if (ratio > 1) {
    return {
      width: 500,
      height: Math.round(500 / ratio),
    }
  }

  return {
    width: Math.round(500 * ratio),
    height: 500,
  }
}

const getPreviewImageUrl = ({ previewURL, bannerURL }: AdGroupCreativeGrouped) => {
  return previewURL || bannerURL || ''
}

const groupedCreatives = computed(() => {
  const groups = groupCreatives(creatives.value).map((group): AdGroupCreativeGrouped[] => {
    const totalWeight = getCreativesTotalWeight(group)
    const firstItem = group[0]!

    return [
      ...group,
      {
        id: 0,
        isTotal: true,
        name: 'Total',
        bannerSize: firstItem.bannerSize,
        width: firstItem.width,
        height: firstItem.height,
        weight: totalWeight,
        creativeStatus: CreativeStatus.DRAFT,
      },
    ]
  })
  return groups.flat(1)
})

const creativesList = computed((): AdGroupCreativeGrouped[] => {
  return weightedRotation.value ? groupedCreatives.value : creatives.value
})

const { pageItems, pageNumber, pageQty: pagesTotal } = usePaginatedArray({
  items: creativesList,
  pageSize: 20,
})

const updateWeight = (id: number, weight: number) => {
  creatives.value = creatives.value.map((creative) => {
    return creative.id === id ? { ...creative, weight } : creative
  })
}

const visible = useTableVisibility({
  pageNumber,
  pagesTotal,
  error: () => null,
  loading: () => false,
})

const getWeightColor = (creative: AdGroupCreativeGrouped) => {
  const weight = creative.weight

  if (!creative.isTotal) {
    return (weight > 100 || weight === null) ? 'error' : 'inherit'
  }

  if (weight > 100) {
    return 'error'
  }

  return weight === 100 ? 'success' : 'inherit'
}
</script>
