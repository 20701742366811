export function compareByOrder<T>(a: T, b: T, order: T[], orderForUnknown: 1 | -1 = 1): number {
  const indexA = order.indexOf(a)
  const indexB = order.indexOf(b)

  if (indexA === -1 || indexB === -1) {
    return indexA === -1 ? indexB === -1 ? 0 : orderForUnknown : -orderForUnknown
  }

  return indexA - indexB
}

export const compareBooleans = (a: unknown, b: unknown): number => {
  const booleanA = Boolean(a)
  const booleanB = Boolean(b)

  return booleanA === booleanB ? 0 : (booleanA && !booleanB) ? 1 : -1
}

export const compareStrings = (a: unknown, b: unknown, orderForEmpty: 1 | -1 = -1): number => {
  const aString = String(a)
  const bString = String(b)

  if (!aString || !bString) {
    return !aString ? !bString ? 0 : orderForEmpty : -orderForEmpty
  }

  return aString.localeCompare(bString)
}

export const compareNumbers = (a: unknown, b: unknown, orderForNaN: 1 | -1 = -1): number => {
  const aNumber = typeof a === 'number' ? a : typeof a === 'string' ? Number(a) : NaN
  const bNumber = typeof b === 'number' ? b : typeof b === 'string' ? Number(b) : NaN
  const isANaN = isNaN(aNumber)
  const isBNaN = isNaN(bNumber)

  if (isANaN || isBNaN) {
    return isANaN ? isBNaN ? 0 : orderForNaN : -orderForNaN
  }

  return aNumber - bNumber
}
