import { setLocale } from 'yup'

import { defaultMessages } from '../validation/messages'
import { extendYup } from '../validation/extend-yup'
import { patchGlobalStorage } from '../hooks/useStorage'

// Note that this is called AFTER describe blocks are evaluated but BEFORE any tests are run
beforeAll(() => {
  patchGlobalStorage()
  setLocale(defaultMessages)
  extendYup()
})
