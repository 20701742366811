/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUniversalPixelAudienceCommand } from '../models/CreateUniversalPixelAudienceCommand';
import type { CreateUniversalPixelAudienceCommandResult } from '../models/CreateUniversalPixelAudienceCommandResult';
import type { CreateUniversalPixelCommand } from '../models/CreateUniversalPixelCommand';
import type { CreateUniversalPixelCommandResult } from '../models/CreateUniversalPixelCommandResult';
import type { GetUniversalPixelAudienceQueryResult } from '../models/GetUniversalPixelAudienceQueryResult';
import type { GetUniversalPixelAudiencesQuery } from '../models/GetUniversalPixelAudiencesQuery';
import type { GetUniversalPixelAudiencesQueryResult } from '../models/GetUniversalPixelAudiencesQueryResult';
import type { GetUniversalPixelQueryResult } from '../models/GetUniversalPixelQueryResult';
import type { GetUniversalPixelsQuery } from '../models/GetUniversalPixelsQuery';
import type { GetUniversalPixelsQueryResult } from '../models/GetUniversalPixelsQueryResult';
import type { PXCommandResultBase } from '../models/PXCommandResultBase';
import type { UpdateUniversalPixelAudienceCommand } from '../models/UpdateUniversalPixelAudienceCommand';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UniversalPixelService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param requestBody
     * @returns CreateUniversalPixelCommandResult OK
     * @throws ApiError
     */
    public postApiUniversalPixelCreate(
        requestBody?: CreateUniversalPixelCommand,
    ): CancelablePromise<CreateUniversalPixelCommandResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/UniversalPixel/Create',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns GetUniversalPixelQueryResult OK
     * @throws ApiError
     */
    public getApiUniversalPixelGetUniversalPixel(
        id: number,
    ): CancelablePromise<GetUniversalPixelQueryResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/UniversalPixel/GetUniversalPixel',
            query: {
                'Id': id,
            },
        });
    }
    /**
     * @param requestBody
     * @returns GetUniversalPixelsQueryResult OK
     * @throws ApiError
     */
    public postApiUniversalPixelGetUniversalPixels(
        requestBody?: GetUniversalPixelsQuery,
    ): CancelablePromise<GetUniversalPixelsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/UniversalPixel/GetUniversalPixels',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns GetUniversalPixelAudienceQueryResult OK
     * @throws ApiError
     */
    public getApiUniversalPixelGetUniversalPixelAudience(
        id: number,
    ): CancelablePromise<GetUniversalPixelAudienceQueryResult> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/UniversalPixel/GetUniversalPixelAudience',
            query: {
                'Id': id,
            },
        });
    }
    /**
     * @param requestBody
     * @returns CreateUniversalPixelAudienceCommandResult OK
     * @throws ApiError
     */
    public postApiUniversalPixelCreateUniversalPixelAudience(
        requestBody?: CreateUniversalPixelAudienceCommand,
    ): CancelablePromise<CreateUniversalPixelAudienceCommandResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/UniversalPixel/CreateUniversalPixelAudience',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public postApiUniversalPixelUpdateUniversalPixelAudience(
        requestBody?: UpdateUniversalPixelAudienceCommand,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/UniversalPixel/UpdateUniversalPixelAudience',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetUniversalPixelAudiencesQueryResult OK
     * @throws ApiError
     */
    public postApiUniversalPixelGetUniversalPixelAudiences(
        requestBody?: GetUniversalPixelAudiencesQuery,
    ): CancelablePromise<GetUniversalPixelAudiencesQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/UniversalPixel/GetUniversalPixelAudiences',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public deleteApiUniversalPixelDeleteUniversalPixelAudience(
        id?: number,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/UniversalPixel/DeleteUniversalPixelAudience',
            query: {
                'Id': id,
            },
        });
    }
    /**
     * @param id
     * @returns PXCommandResultBase OK
     * @throws ApiError
     */
    public deleteApiUniversalPixelDeleteUniversalPixel(
        id?: number,
    ): CancelablePromise<PXCommandResultBase> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/UniversalPixel/DeleteUniversalPixel',
            query: {
                'Id': id,
            },
        });
    }
}
