import * as am4core from '@amcharts/amcharts4/core'

import { generalConfig } from './generalConfig'

// Forest theme colors
const colors = {
  main: '#00A3E0',
  dark: '#003346',
  light: '#FAFDFF',
  stroke: '#AFE2F5', // no color spec in figma palette
  contrastText: '#FFFFFF',
}

// Forest theme colors
export const chartAltPalette = [
  am4core.color('#33B5E6'),
  am4core.color('#55C1EA'),
  am4core.color('#77CDEE'),
  am4core.color('#99D9F2'),
  am4core.color('#BBE5F6'),
  am4core.color('#BAE5F8'),
]

export const am4themesAlt: am4core.ITheme = (target: am4core.BaseObject): void => {
  if (target instanceof am4core.ColorSet) {
    target.list = [...chartAltPalette]
  }

  if (target instanceof am4core.InterfaceColorSet) {
    // Navbar links, Zoom out button, Focus filter.

    target.setFor('primaryButton', am4core.color(colors.main))
    target.setFor('primaryButtonHover', am4core.color(colors.dark))
    target.setFor('primaryButtonActive', am4core.color(colors.main))
    target.setFor('primaryButtonText', am4core.color(colors.contrastText))
    target.setFor('primaryButtonStroke', am4core.color(colors.stroke))

    // Scrollbar, Map polygons, Scrollbar, and most of the buttons.

    target.setFor('secondaryButton', am4core.color(colors.main))
    target.setFor('secondaryButtonHover', am4core.color(colors.dark))
    target.setFor('secondaryButtonActive', am4core.color(colors.main))
    target.setFor('secondaryButtonText', am4core.color(colors.contrastText))
    target.setFor('secondaryButtonStroke', am4core.color(colors.stroke))

    // Fill of any container, including main chart element, Axis break fill, XY Chart Scrollbar background, etc..
    target.setFor('background', am4core.color(colors.light))

    // Axis fills, full-width XY cursor, Gauge clock hand, Flow diagram links, Shadow filter, misc map controls, Navigation bar arrow, etc.
    target.setFor('alternativeBackground', am4core.color(colors.main))

    // Axis tooltip text, Scrollbar grip visual elements.
    target.setFor('alternativeText', am4core.color(colors.contrastText))
  }
  // Add general settings to the theme
  generalConfig(target)
}
