/* v8 ignore start */
import { Plugin } from 'vue'

import { handleModuleLoadError, isModuleLoadError } from '../utils'

export const errorHandlerPlugin: Plugin = (app) => {
  if (process.env.COMMAND === 'serve') {
    return
  }

  app.config.errorHandler = (error, instance, info) => {
    if (isModuleLoadError(error)) {
      handleModuleLoadError()
    }

    if (process.env.NODE_ENV === 'development') {
      console.error(info, error, instance)
    }
    else {
      console.error(error)
    }
  }

  const router = app.config.globalProperties.$router

  if (router) {
    const removeErrorHandler = router.onError((error: unknown) => {
      if (isModuleLoadError(error)) {
        handleModuleLoadError()
      }
    })

    app.onUnmount(() => {
      removeErrorHandler()
    })
  }
}
