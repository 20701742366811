import { ComponentPublicInstance, onMounted, ref } from 'vue'
import { unrefElement, useResizeObserver } from '@vueuse/core'

import { RefOrGetter } from '../types'

import { onUpdatedSafe } from './onUpdatedSafe'

export const useElementOverflown = (
  elRef: RefOrGetter<ComponentPublicInstance | HTMLElement | null | undefined>,
  { direction = 'x', hasOverflow }: {
    direction?: 'x' | 'y'
    hasOverflow?: (el: HTMLElement | SVGElement) => boolean
  } = /* v8 ignore next */ {},
) => {
  const overflown = ref(false)

  const hasOverflowInternal = (el: HTMLElement | SVGElement): boolean => {
    if (direction === 'x') {
      return el.scrollWidth > el.clientWidth
    }
    else {
      return el.scrollHeight > el.clientHeight
    }
  }

  const updateOverflown = () => {
    const el = unrefElement(elRef)

    if (el) {
      overflown.value = hasOverflow?.(el) ?? hasOverflowInternal(el)
    }
    else {
      overflown.value = false
    }
  }

  onMounted(updateOverflown)
  onUpdatedSafe(updateOverflown)
  useResizeObserver(elRef, updateOverflown)

  return { overflown }
}
