<template>
  <SelectPanelItemBase
    v-model:expanded="expanded"
    :searchQuery="searchQuery"
    :chip="chip"
    :label="label"
    :info="info"
    :disabled="disabled"
    :expandable="expandable"
    :level="level"
    :singleLine="singleLine"
    :onClickInfo="onClickInfo"
  >
    <template #control>
      <Toggle
        v-if="withToggle"
        v-model="modelValue"
        :value="value as any"
        :color="toggleOnColor"
        :offColor="toggleOffColor"
        :readonly="disabled"
        size="xs"
        @click.stop
      />
      <Checkbox
        v-else
        v-model="modelValue"
        :value="value as any"
        :disabled="disabled"
        :indeterminate="indeterminate"
        @click.stop
      />
    </template>
    <template v-if="slots.default" #default="slotProps">
      <slot name="default" v-bind="slotProps" />
    </template>
    <template v-if="slots.content" #content>
      <slot name="content" />
    </template>
    <template v-if="slots.chip" #chip>
      <slot name="chip" />
    </template>
    <template v-if="slots.label" #label>
      <slot name="label" />
    </template>
    <template v-if="slots.extra" #extra>
      <slot name="extra" />
    </template>
  </SelectPanelItemBase>
</template>

<script setup lang="ts" generic="T extends boolean | any[]">
import { Toggle, ToggleColor } from '../Toggle'
import { Checkbox } from '../Checkbox'

import SelectPanelItemBase from './SelectPanelItemBase.vue'

const {
  chip,
  searchQuery,
  label,
  info,
  disabled,
  expandable,
  level,
  value,
  withToggle = false,
  singleLine = false,
  toggleOnColor = 'success',
  toggleOffColor = 'error',
} = defineProps<{
  value?: T extends any[] ? T[number] : never
  indeterminate?: boolean
  searchQuery?: string
  chip?: string | number | Array<string | number>
  label?: string
  info?: string
  disabled?: boolean
  expandable?: boolean
  level?: number
  singleLine?: boolean
  withToggle?: boolean
  toggleOnColor?: ToggleColor
  toggleOffColor?: ToggleColor
  onClickInfo?: Function
}>()

defineEmits<{
  'clickInfo': []
}>()

const slots = defineSlots<{
  default?: (props: { nextLevel: number }) => any
  content?: () => any
  chip?: () => any
  label?: () => any
  extra?: () => any
}>()

const modelValue = defineModel<T>({ required: true })
const expanded = defineModel<boolean>('expanded', { default: false })
</script>

<style scoped>
.select-panel-item {
  @apply border-b border-base-500 last-of-type:border-b-0;
}

.select-panel-item-inner {
  @apply cursor-pointer flex items-center gap-2 grow flex-row px-4 py-1 min-h-[34px] w-full;
  padding-left: calc(16px * calc(v-bind(level) + 1));
}

.select-panel-item-content {
  @apply flex items-center gap-2 grow min-w-0;
}

.select-panel-item-children {
  @apply border-t border-base-500;
}

.select-panel-item-label {
  @apply basis-0 grow;
}

.select-panel-item-label.singleLine {
  @apply whitespace-nowrap truncate;
}

.select-panel-extra {
  @apply flex flex-grow justify-end;
}

.select-panel-item:not(.expandable) .select-panel-extra {
  @apply pr-6;
}

.select-panel-extra-inner {
  @apply flex gap-0.5;
}
</style>
