/* v8 ignore start */
import { VueGtmUseOptions } from '@gtm-support/vue-gtm'

export const getGtmConfig = (): VueGtmUseOptions => ({
  id: [process.env.PUBLIC_GOOGLE_TAG_MANAGER_ID || 'GTM-UNDEFINED'],
  defer: false,
  compatibility: false,
  enabled: process.env.COMMAND !== 'serve',
  debug: process.env.NODE_ENV !== 'production',
  loadScript: true,
  trackOnNextTick: false,
})
