<template>
  <Draggable
    v-bind="props"
    v-model="modelValue"
    data-role="select-panel-draggable"
    class="!h-auto"
  >
    <slot />
  </Draggable>
</template>

<script setup lang="ts" generic="T">
import { Draggable, DraggableProps } from '../Draggable'

const props = withDefaults(defineProps<DraggableProps<T>>(), {
  preventOnFilter: true,
  sort: false,
  removeCloneOnHide: true,
})

const modelValue = defineModel<T[]>({ required: true })
</script>
