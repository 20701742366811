import { Ref, ref } from 'vue'

/**
 * Allows attaching a pending ref to async functions.
 * It will be true while at least one promise from one of the functions is pending.
 *
 * @example
 * const [loading, withLoading] = usePendingState()
 * const loadSomething = withLoading(async () => {...})
 *
 * const promise1 = loadSomething()
 * const promise2 = loadSomething()
 * loading.value === true
 * await promise1
 * loading.value === true
 * await promise2
 * loading.value === false
 */
export const usePendingState = (): [
  pending: Readonly<Ref<boolean>>,
  withPending: <T extends (...args: any[]) => Promise<unknown>>(callback: T) => T,
] => {
  const pending = ref(false)
  let promise: Promise<unknown> | null = null

  return [
    pending,
    <T extends (...args: any[]) => Promise<unknown>>(callback: T) => (async (...args) => {
      pending.value = true
      let lastPromise = null
      try {
        lastPromise = promise = callback(...args)

        return await lastPromise
      }
      finally {
        if (lastPromise === promise) {
          pending.value = false
        }
      }
    }) as T,
  ]
}
