import { BaseSchema, TypeOf } from 'yup'
import { useForm } from 'vee-validate'
import { pick } from 'lodash-es'
import { Asserts } from 'yup/lib/util/types'

import { toRef, toValue } from 'vue'

import { useToast } from '../useToast'

import { useFormAddons } from './useFormAddons'
import { UseBetterFormOptions, UseBetterFormReturn } from './types'
import { formProps } from './consts'

/**
 * Wrapper over {@link import('vee-validate').useForm} that provides additional and improved methods and properties.
 */
export const useBetterForm = <Schema extends BaseSchema>({
  validationSchema,
  initialValues,
  onMissingField,
}: UseBetterFormOptions<Schema, TypeOf<Schema>>): UseBetterFormReturn<TypeOf<Schema>, Asserts<Schema>> => {
  const form = useForm<TypeOf<Schema>, Asserts<Schema>>({
    validationSchema,
    initialValues: toValue(initialValues),
    keepValuesOnUnmount: true,
  })

  const formBase = pick(form, formProps)

  const formAddons = useFormAddons(form, {
    initialValues: toRef(initialValues),
    onMissingField: onMissingField ?? useToast().error,
  })

  return {
    ...formBase,
    ...formAddons,
  }
}
