/**
 * Allows asserting that types extend each other at compile time
 *
 * @example
 * assertTypeExtends<boolean, true>() // no error
 * assertTypeExtends<true, boolean>() // error
 */
/* v8 ignore next 2 */
// eslint-disable-next-line unused-imports/no-unused-vars
export const assertTypeExtends = <T1 extends T2, T2>() => {}
