import { setAdGroupPublishers } from '../utils/adGroupUtils'
const { forEach } = require("angular");

(function () {
    'use strict';

    angular.module('app')
        .controller('campaignWizardController', ['$scope', '$rootScope', '$location', '$timeout', 'hub', '$sce', 'accountService', 'campaignService', 'creativeService', 'validationService', 'authService', '$routeParams', '$anchorScroll', 'logger', function ($scope, $rootScope, $location, $timeout, hub, $sce, accountService, campaignService, creativeService, validationService, authService, $routeParams, $anchorScroll, logger) {

            var vm = this;
            var rootVM = $rootScope.rootVM;
            vm.rootVM = rootVM;
            vm.log = hub.getLog('Campaign Management');
            vm.logger = logger;

            const MARKETER_STEP = 1;
            const ADVERTISER_STEP = 2;
            const CAMPAIGN_STEP = 3;
            vm.hasFeature = authService.hasFeature;
            vm.step = 1;
            vm.account = accountService.get();

            vm.isObserver = authService.isObserver;
            vm.canLaunch = authService.isSuperAdmin() || authService.hasAccess('campaigns', 'Launch');

            vm.isAdvertiser = authService.userAccountType() === "Advertiser";
            vm.isAdvertiserGroup = authService.userAccountType() === "AdvertiserGroup";
            vm.isAdmin = vm.isAdmin = authService.userAccountType() === "Admin";

            vm.activeTab = 0;

            function getPublishers() {
                hub.webapicontext.getPublishers().then(function (publishers) {
                    vm.publishers = publishers;
                    vm.publishers.forEach(function (pub) {
                        var pubFound = vm.adGroup.publishers.find(p => p.id === pub.id);
                        if (pubFound) {
                            pub.selected = true;
                            pub.excluded = pubFound.excluded;
                            pubFound.name = pub.name;
                        }
                        pub.placements.forEach(function (place) {
                            var placeFound = vm.adGroup.monetizationPlacements.find(p => p.id === place.id);
                            if (placeFound) {
                                place.selected = true;
                                place.excluded = placeFound.excluded;
                                placeFound.name = place.name;
                                pub.open = true;
                            }
                            place.adUnits.forEach(function (adUnit) {
                              const adUnitFound = vm.adGroup.adUnits?.find(a => a.id === adUnit.id);
                              if (adUnitFound) {
                                adUnitFound.name = adUnit.name;
                                adUnit.selected = true;
                                adUnit.excluded = adUnitFound.excluded;
                                place.open = true;
                              }
                            });
                        });
                    });
                });
            }
            getPublishers();

            function loadData() {
                var reload = vm.step === 1 && !campaignService.marketers;
                campaignService.getAccounts(2, reload).then(function () {
                    vm.marketers = campaignService.marketers;
                    vm.advertisers = campaignService.advertisers;
                    if (!vm.campaign && vm.account && (vm.account.accountType == 3 || vm.account.accountType == 1))
                        vm.filteredAdvertisers = vm.advertisers;
                    if (vm.campaign && vm.campaign.marketerId)
                        vm.filteredAdvertisers = vm.advertisers.filter(function (a) { return a.marketerId === vm.campaign.marketerId; });
                    setData();
                });
            }

            function setData() {

                if (vm.account && vm.account.accountType == 1) {
                    if (!vm.campaign) {
                        vm.selectedMarketerId = vm.account.marketerId;
                        vm.selectedAdvertiserId = vm.account.id;
                        if (!vm.campaign) {
                            vm.campaign = { adGroups: [], trackingCodes: [{}], budgetTypeId: 1, advertiserId: vm.account.id, marketerId: vm.account.marketerId, plCodes: [], conceptGroups: [] };
                            vm.adGroup = campaignService.getSelectedAdGroup();
                            if (!vm.adGroup.id) {
                                vm.adGroup = { trackingCodes: [{}], creatives: [], marketerId: vm.selectedMarketerId, advertiserId: vm.selectedAdvertiserId, selectedLocations: [], audienceSegmentGroups: [], brandSafetySegments: [], connectedTVSegments: [], optimizationConversionSegments: [], conversionSegments: [], locationSegments: [], deviceTypes: [1, 2, 4, 16], supplyTypes: [1, 2, 4], placements: [1, 2, 4], countries: ["US"], audienceLoyality: [false, false, false, false, false, false, false, false, false], excludeMyAdvertiserCustomers: false, browsers: true, buyers: true, recencyFrom: 0, recencyTo: 30, objectives: [] };
                                campaignService.setSelectedAdGroup(vm.adGroup);
                            }
                            vm.campaign.adGroups.push(vm.adGroup);
                            campaignService.setSelectedCampaign(vm.campaign);
                        }
                        campaignService.setSelectedCampaign(vm.campaign);
                        vm.step = CAMPAIGN_STEP;
                    }
                } else if (vm.account && vm.account.accountType == 2) {
                    vm.selectedMarketerId = vm.account.id;
                    if (!vm.campaign) {
                        vm.campaign = { adGroups: [], trackingCodes: [{}], budgetTypeId: 1, marketerId: vm.account.id, plCodes: [], conceptGroups: [] };
                        vm.step = ADVERTISER_STEP;
                    }
                } else if (vm.account && vm.account.accountType == 3) {
                    if (!vm.campaign) {
                        vm.campaign = { adGroups: [], trackingCodes: [{}], budgetTypeId: 1, plCodes: [], conceptGroups: [] };
                        vm.step = MARKETER_STEP;
                    }
                }


                vm.selectedAdvertiserId = vm.campaign.advertiserId;
                if (!vm.campaign.advertiser && vm.selectedAdvertiserId) {
                    vm.campaign.advertiser = campaignService.advertisers.filter(function (b) { return b.id == vm.selectedAdvertiserId; })[0];
                }


                if (vm.campaign.advertiser) {
                    vm.selectedAdvertiserId = vm.campaign.advertiserId;
                    vm.selectedAdvertiser = vm.campaign.advertiser;
                }

                if (vm.campaign.marketerId) {
                    vm.campaign.marketer = campaignService.marketers.filter(function (r) { return r.id == vm.campaign.marketerId; })[0];
                    vm.selectedMarketer = vm.campaign.marketer;
                }

                if (vm.campaign.channelId)
                    vm.campaign.channel = campaignService.getChannels().filter(function (c) { return c.id == vm.campaign.channelId; })[0];


                if (vm.campaign.creativeTypeId) {
                    vm.campaign.creativeType = vm.creativeTypes.filter(function (ct) { return ct.id == vm.campaign.creativeTypeId; })[0];
                    vm.selectedCreativeType = vm.campaign.creativeType;
                    vm.selectedCreativeTypeId = vm.campaign.creativeTypeId;
                }

                if (!vm.rootVM.campaignEditMode) {
                    if (vm.campaign && vm.campaign.marketerId && !(vm.account && vm.accountType == 2)) {
                        if (vm.campaign.campaignId && !vm.campaign.noMarketer)
                            vm.selectedMarketerId = vm.campaign.marketerId;
                        else if (vm.campaign.campaignId && vm.campaign.noMarketer)
                            vm.selectedMarketerId = -1;

                        vm.selectedMarketerChange();
                    }
                    hub.page.setTitle('Create New Campaign');
                } else {
                    var selectedCampaign = campaignService.getSelectedCampaign();
                    vm.campaign = angular.copy(selectedCampaign);
                    if (vm.campaign.campaignId && !vm.campaign.noMarketer)
                        vm.selectedMarketerId = vm.campaign.marketerId;
                    else if (vm.campaign.campaignId && vm.campaign.noMarketer)
                        vm.selectedMarketerId = -1;
                    hub.page.setTitle('Edit ' + vm.campaign.name);

                    if (campaignService.marketers) {
                        vm.campaign.marketer = campaignService.marketers.filter(function (r) { return r.id == vm.campaign.marketerId; })[0];
                        vm.selectedMarketer = vm.campaign.marketer;
                    }
                }
                if (vm.campaign.campaignId)
                    vm.step = CAMPAIGN_STEP;

                if (!vm.campaign.trackingCodes || vm.campaign.trackingCodes.length === 0)
                    vm.campaign.trackingCodes = [{}];

                if (vm.campaign.adGroups.length === 0 && vm.selectedMarketerId && vm.selectedAdvertiserId) {
                    var adGroup = { channelIndex: 0, trackingCodes: [{}], creatives: [], marketerId: vm.selectedMarketerId, advertiserId: vm.selectedAdvertiserId, selectedLocations: [], audienceSegmentGroups: [], brandSafetySegments: [], connectedTVSegments: [], optimizationConversionSegments: [], conversionSegments: [], locationSegments: [], deviceTypes: [1, 2, 4], supplyTypes: [1, 2, 4], placements: [1, 2, 4], countries: ["US"], audienceLoyality: [false, false, false, false, false, false, false, false, false], excludeMyAdvertiserCustomers: false, browsers: true, buyers: true, recencyFrom: 0, recencyTo: 30, objectives: [] };
                    vm.campaign.adGroups.push(adGroup);
                }

                if (vm.campaign.adGroups)
                    vm.campaignHasActiveAdGroups = vm.campaign.adGroups.find(function (ag) { return ag.adGroupStatusId == 1; });

                vm.campaign.isLaunch = vm.campaign.campaignStatusId === 1;
                vm.campaign.initialCampaignStatus = vm.campaign.campaignStatusId;
                vm.changeCampaignStatus = function () {
                    if (vm.campaign.initialCampaignStatus === 1) {
                        vm.campaign.initialCampaignStatus = -1;
                        vm.campaign.isLaunch = false;
                    }
                    else
                        vm.campaign.isLaunch = !vm.campaign.isLaunch;
                };

                vm.changeCampaignIsProact = function () {
                    if (!vm.campaign.isProactDisabled)
                        vm.campaign.isProact = !vm.campaign.isProact;
                };
            }

            vm.conceptGroupKeywordChanged = function () {
                const payload = {
                    pager: {
                        pageSize: 10,
                        page: 1
                    },
                    filter: {
                        name: { like: vm.conceptGroupKeyword },
                        domain: null
                    },
                };

                hub.webapicontext.getConceptGroups(payload).then(function (data) {
                    vm.conceptGroups = data.list;
                    vm.conceptGroups.forEach(function (cg) {
                        if (vm.campaign.conceptGroups.indexOf(cg) > -1)
                            cg.selected = true;
                    });

                    vm.conceptGroups = vm.conceptGroups.filter(function (cg) {
                        return !vm.campaign.conceptGroups.find(function (c) { return cg.id == c.id; });
                    });

                    if (vm.conceptGroups && vm.conceptGroups.length > 0)
                        vm.showConceptGroupsList = true;
                });
            }

            vm.addConceptGroup = function (cg) {
                if (cg.selected || vm.campaign.conceptGroups.length > 0)
                    return;
                vm.campaign.conceptGroups.push(cg);
                vm.conceptGroupKeyword = '';
                vm.conceptGroupKeywordChanged();
            }

            vm.removeConceptGroup = function ($event, cg) {
                var checked = $event.target.checked;
                var cgIndex = vm.campaign.conceptGroups.indexOf(cg);

                if (!checked)
                    vm.campaign.conceptGroups.splice(cgIndex, 1);

                vm.conceptGroupKeywordChanged();
            }

            vm.nextStepEnabled = function () {
                switch (vm.step) {
                    case MARKETER_STEP:
                        return vm.selectedMarketerId;
                    case ADVERTISER_STEP:
                        return vm.selectedAdvertiserId;
                    default:
                        return false;
                }
            };

            vm.nextStep = function () {
                vm.step++;
                setData();
            };

            vm.goToStep = function (step) {
                switch (step) {
                    case MARKETER_STEP:
                        if (vm.campaign && vm.campaign.campaignId)
                            vm.gotoHash('advertiser');
                        else
                            vm.step = MARKETER_STEP;
                        break;
                    case ADVERTISER_STEP:
                        if (vm.campaign && vm.campaign.campaignId)
                            vm.gotoHash('audiencetype');
                        else
                            if (vm.campaign && vm.campaign.advertiser)
                                vm.step = ADVERTISER_STEP;
                        break;
                    case CAMPAIGN_STEP:
                        if (vm.campaign && vm.campaign.campaignId)
                            vm.gotoHash('campaign');
                        else
                            if (vm.campaign)
                                vm.step = CAMPAIGN_STEP;
                        break;
                    default:
                        {
                            if (vm.campaign)
                                vm.step = CAMPAIGN_STEP;

                            vm.gotoHash(step);
                        }
                }
            };

            vm.addChannel = function () {
                var adGroup = { primaryAdGroup: vm.campaign.adGroups[vm.campaign.adGroups.length - 1], channelIndex: vm.campaign.adGroups.length, trackingCodes: [{}], creatives: [], marketerId: vm.selectedMarketerId, advertiserId: vm.selectedAdvertiserId, selectedLocations: [], audienceSegmentGroups: [], brandSafetySegments: [], connectedTVSegments: [], optimizationConversionSegments: [], conversionSegments: [], locationSegments: [], deviceTypes: [1, 2, 4], supplyTypes: [1, 2, 4], placements: [1, 2, 4], countries: ["US"], audienceLoyality: [false, false, false, false, false, false, false, false, false], excludeMyAdvertiserCustomers: false, browsers: true, buyers: true, recencyFrom: 0, recencyTo: 30, objectives: [] };
                vm.campaign.adGroups.push(adGroup);
            };

            $rootScope.$on('deleteChannel', function (e, adGroup) {
                var removed = false;
                for (var i = 0; i < vm.campaign.adGroups.length; i++) {
                    if (!removed && adGroup.channelIndex === vm.campaign.adGroups[i].channelIndex) {
                        vm.campaign.adGroups.splice(i, 1);
                        removed = true;
                    }
                    if (removed && vm.campaign.adGroups[i])
                        vm.campaign.adGroups[i].channelIndex--;
                }
            });

            vm.getChannelNameById = function (id) {
                var channel = campaignService.getChannels().find(function (c) { return c.id === id; });
                if (channel)
                    return channel.name;
            };

            vm.getChannelPriorityByIndex = function (index) {
                switch (index + 1) {
                    case 1:
                        return 'Primary';
                    case 2:
                        return 'Secondary';
                    case 3:
                        return 'Third';
                    case 4:
                        return 'Fourth';
                    default:
                        return '';
                }
            };

            var m = moment.utc();
            vm.currentEastTimeAbbreviation = m.tz('America/New_York').format('z');
            var now = new Date();
            var offset = -5.00;
            if (vm.currentEastTimeAbbreviation === "EDT")
                offset = -4.00;
            var tzDifference = offset * 60 + now.getTimezoneOffset();
            vm.startCalendarDate = new Date(now.getTime() + tzDifference * 60 * 1000);
            vm.endCalendarDate = new Date(now.getTime() + tzDifference * 60 * 1000);

            vm.gotoHash = function (hash) {
                $anchorScroll(hash);
            };

            vm.selectedAdvertiserChange = function () {
                if (!vm.campaign)
                    vm.campaign = { adGroups: [], trackingCodes: [{}], budgetTypeId: 1, conceptGroups: [] };

                vm.campaign.advertiserId = vm.selectedAdvertiserId;
                vm.campaign.advertiser = vm.advertisers.filter(function (b) { return b.id == vm.selectedAdvertiserId; })[0];
                if (vm.account && vm.account.accountType == 3)
                    vm.selectedMarketerId = vm.campaign.advertiser.marketerId;

                if (vm.account && vm.account.accountType == 2) {
                    vm.campaign.marketerId = vm.account.id;
                    campaignService.setSelectedMarketerId(vm.account.id);
                } else if (vm.account && vm.account.accountType == 3) {
                    vm.campaign.marketerId = vm.selectedMarketerId;
                }


                if (!vm.campaign.adGroups[0]) {
                    var adGroup = { channelIndex: 0, trackingCodes: [{}], creatives: [], marketerId: vm.selectedMarketerId, advertiserId: vm.selectedAdvertiserId, selectedLocations: [], audienceSegmentGroups: [], brandSafetySegments: [], connectedTVSegments: [], optimizationConversionSegments: [], conversionSegments: [], locationSegments: [], deviceTypes: [1, 2, 4, 16], supplyTypes: [1, 2, 4], placements: [1, 2, 4], countries: ["US"], audienceLoyality: [false, false, false, false, false, false, false, false, false], excludeMyAdvertiserCustomers: false, browsers: true, buyers: true, recencyFrom: 0, recencyTo: 30, objectives: [] };
                    vm.campaign.adGroups.push(adGroup);
                }

                vm.campaign.advertiser = vm.advertisers.filter(function (b) { return b.id == vm.selectedAdvertiserId; })[0];
                campaignService.setSelectedCampaign(vm.campaign);
                setData();
            };

            vm.creativeTypes = [
                { id: 1, name: 'Single Image and/or Carousel' },
                { id: 3, name: 'Collection/Canvas' }
            ];

            vm.selectedCreativeTypeChange = function () {
                vm.campaign.creativeTypeId = vm.selectedCreativeTypeId;
                campaignService.setSelectedCampaign(vm.campaign);
            };

            vm.filteredAdvertisers = [];
            vm.selectedMarketerChange = function () {
                if (vm.selectedMarketerId != -1) {
                    if (!vm.campaign)
                        vm.campaign = { adGroups: [], trackingCodes: [{}], budgetTypeId: 1, conceptGroups: [] };
                    vm.campaign.marketerId = vm.selectedMarketerId;
                }

                if (vm.campaign.marketerId && !vm.campaign.campaignId) {
                    vm.campaign.adGroups = [];
                    campaignService.setSelectedMarketerId(vm.campaign.marketerId);
                }
                else {
                    if (vm.campaign) {
                    }
                    else {
                        vm.campaign = { adGroups: [], trackingCodes: [{}], marketerId: vm.selectedMarketerId, budgetTypeId: 1, conceptGroups: [] };
                    }
                }
                vm.filteredAdvertisers = vm.advertisers.filter(function (a) { return a.marketerId === vm.campaign.marketerId; });
            };

            vm.campaignNameChanged = function () {
                vm.campaign.duplicateName = false;
                var validationData = {};
                validationData.entityName = vm.campaign.name;
                validationData.entityType = "campaign";
                validationData.campaignId = vm.campaign.campaignId;
                validationData.advertiserId = vm.selectedAdvertiser ? vm.selectedAdvertiser.id : vm.advertisers[0].id;
                hub.webapicontext.validateName(validationData).then(function (data) {
                    if (data.message !== "Valid Name")
                        vm.campaign.duplicateName = true;
                });
            };

            vm.processUrl = function () {
                var campaignId = $routeParams.campaignId;
                if (campaignId) {
                    vm.campaign = campaignService.getSelectedCampaign();
                    if (vm.campaign && vm.campaign.campaignId == campaignId) {
                        vm.loadingCampaignById = false;
                        var ag = vm.campaign.adGroups[0];
                        loadData();
                    }
                    else {
                        vm.loadingCampaignById = true;
                        hub.webapicontext.getCampaign(campaignId).then(function (result) {
                            if (result) {
                                var campaign = result[0];
                                campaignService.setSelectedCampaign(campaign, campaign.adGroups[0]);
                                vm.campaign = campaign;
                                loadData();
                            }
                        });
                    }
                }
                else
                    loadData();
            };

            vm.processUrl();

            vm.removeTrackingCode = function (list, tc) {
                list.splice($.inArray(tc, list), 1);
            };

            vm.cancel = function () {
                vm.rootVM.cancelCampaign();
                $location.path("/activation/campaign");
            };

            $scope.canSaveProgress = function () {
                if (!document.forms["$parent.adGroupForm"])
                    return false;
                var campaignFormValid = typeof this.$parent.newCampaignForm !== 'undefined' ? this.$parent.newCampaignForm.$valid : false;
                campaignFormValid = campaignFormValid && !vm.campaign.duplicateName;
                var adGroupFormValid = true;
                if (typeof document.forms["$parent.adGroupForm"] !== 'undefined')
                    vm.campaign.adGroups.forEach(function (adGroup) {
                        adGroupFormValid = adGroupFormValid && validationService.validateAdGroup(adGroup, vm.campaign, false);
                    });

                return campaignFormValid && adGroupFormValid;
            }

            $scope.canSubmit = function () {
                if (!document.forms["$parent.adGroupForm"])
                    return false;

                var campaignFormValid = typeof this.$parent.newCampaignForm !== 'undefined' ? this.$parent.newCampaignForm.$valid : false;

                campaignFormValid = campaignFormValid && !vm.campaign.duplicateName;
                var adGroupFormValid = true;
                if (typeof document.forms["$parent.adGroupForm"] !== 'undefined')
                    vm.campaign.adGroups.forEach(function (adGroup) {
                        adGroupFormValid = adGroupFormValid && validationService.validateAdGroup(adGroup, vm.campaign, false);
                    });

                return campaignFormValid && adGroupFormValid;
            }

            vm.createNewAdGroup = function () {
                var advertiserId = campaignService.getSelectedCampaign().advertiserId;

                var adGroup = vm.rootVM.clearAdGroup();
                if (advertiserId) {
                    adGroup.advertiserId = advertiserId;
                }
                campaignService.setSelectedAdGroup(adGroup);

                vm.autocomplete = '';
                vm.showAdGroupsTable = false;
            };

            $scope.saveCampaign = function (launch) {
                $rootScope.$broadcast('cleanTrackingCodeValidation');
                $timeout(function () {
                    saveCampaign(launch);
                });
            };

            function saveCampaign(launch) {
                var validationFailed = false;
                var eventStartTime = new Date();
                var errors = $('.has-error');
                vm.campaign.validation = true;
                vm.campaign.adGroups[0].validation = true;
                if (vm.campaign.adGroups[0].isMeasurementOnly && vm.campaign.adGroups[0].channelId !== 9) {
                    if (!vm.campaign.name || !vm.campaign.adGroups[0].name ||
                        vm.campaign.duplicateName || vm.campaign.adGroups[0].duplicateName) {
                        $('#newCampaignForm').validator('destroy');
                        $('#newCampaignForm').validator('validate');
                        $('#adGroupForm').validator('destroy');
                        $('#adGroupForm').validator('validate');
                        return;
                    }

                    if (!vm.campaign.adGroups[0].creatives || vm.campaign.adGroups[0].creatives.length === 0 || !vm.campaign.adGroups[0].creatives[0].name) {
                        vm.campaign.adGroups[0].validate = true;
                        $('#adGroupForm').validator('destroy');
                        $('#adGroupForm').validator('validate');
                        return;
                    }

                    var measCamp = angular.copy(vm.campaign);
                    measCamp.marketer = null;
                    measCamp.advertiser = null;
                    hub.webapicontext.saveCampaign(measCamp, true).then(function (c) {
                        vm.isInProgress = false;

                        logEvent(vm.campaign, eventStartTime);
                        if (vm.campaign.campaignId && vm.campaign.campaignId != 0)
                            vm.log("Campaign " + vm.campaign.name + " has been updated");
                        else
                            vm.log("Campaign " + vm.campaign.name + " has been created");

                        vm.rootVM.cancelCampaign(true);
                        $location.path("/activation/campaign");
                    });
                    return;
                }

                vm.campaign.adGroups.forEach(function (adGroup) {
                    if(adGroup.optimizationGoalTypeId === 3){   // CPA
                        if(adGroup.optimizationConversionSegments.length != 1){
                            vm.logger.logError("Must choose one optimization conversion pixel.", null, '', true, true, 300);
                            validationFailed = true;
                            return;
                        }
                        let foundOptimizationConversionSegment = false;
                        adGroup.conversionSegments.forEach(c => {
                            if(c.id ==adGroup.optimizationConversionSegments[0].id){
                                foundOptimizationConversionSegment = true;
                            }
                        });
                        if(!foundOptimizationConversionSegment){
                            vm.logger.logError("Need matching conversion pixel for optimization pixel.", null, '', true, true, 30);
                            validationFailed = true;
                            return;
                        }
                        adGroup.optimizationConversionSegments.forEach(o =>{
                            if(o.excluded){
                                vm.logger.logError("Negatively targeting a optimization conversion pixel is not allowed.", null, '', true, true, 30);
                                validationFailed = true;
                                return;
                            }
                            adGroup.conversionSegments.forEach(c => {
                                if(o.id == c.id && c.excluded){
                                    vm.logger.logError("Negatively targeting a conversion pixel is not allowed.", null, '', true, true, 30);
                                    validationFailed = true;
                                    return;
                                }
                            });
                        });
                        if(adGroup.cpaGoalTypeId == 1 && adGroup.cpaModeId == 2 ){
                            // If Post-click & Post-view + Retargeting is selected, require an audience to be targeted with type=Web
                            if(adGroup.audienceSegmentGroups.length < 1){
                                vm.logger.logError("At least one audience of type Web, Custom, Conversion or Retargeting is required.", null, '', true, true, 30);
                                validationFailed = true;
                                return;
                            }
                            let found = false;
                            adGroup.audienceSegmentGroups.forEach(s => {
                                s.segments.forEach(a => {
                                    if(["web", "custom", "retargeting", "conversion"].indexOf(a.audienceType.toLowerCase()) > -1) {
                                        found = true;
                                    }
                                });
                            });
                            if(!found){
                                vm.logger.logError("At least one audience of type Web, Custom, Conversion or Retargeting is required.", null, '', true, true, 30);
                                validationFailed = true;
                                return;
                            }
                        }
                        if(adGroup.cpaGoalTypeId == 2 && !(adGroup.maintainCPC > 0)){
                            // Post-click only
                            vm.logger.logError("Maintain CPC value must be greater than 0.", null, '', true, true, 30);
                            document.getElementById("maintainCPC").focus();
                            validationFailed = true;
                            return;
                        }
                    }

                    var canSaveProgress = $scope.canSaveProgress();
                    var canSubmit = $scope.canSubmit();
                    validationFailed = !canSaveProgress || !canSubmit;

                    if (validationFailed || adGroup.endDateError || adGroup.splitsInvalid) {
                        var now = new Date();
                        var startDate = new Date(adGroup.startDate);
                        vm.startDateValid = adGroup.channelId === 9 || startDate >= moment(now).add(10, 'm').toDate();
                        var endDate = new Date(adGroup.endDate);
                        vm.endDateValid = adGroup.endDate == null || (endDate >= startDate && Math.ceil((endDate.getTime() - now.getTime()) / 60 / 1000) >= 30);
                        vm.dateRangeValid = adGroup.endDate == null || Math.abs(endDate - startDate) / 1000 / 60 >= 59;

                        $timeout(function () {
                            $('#adGroupForm').validator('destroy');
                            $('#adGroupForm').validator('validate');
                            $('#newCampaignForm').validator('validate');
                            $('#dateRangeValidation').trigger('input');
                            $('#startDateValidation').trigger('input');
                            $('#endDateValidation').trigger('input');
                            $('#impsOverLifetime').trigger('input');
                            $('#impsPerUserPerDay').trigger('input');
                            $('#impsPerUserPerWeek').trigger('input');
                            $('#optimizationGoal').trigger('input');
                            $('#optimizationGoalTypeId').trigger('input');
                            $('#spendBudgetCap').trigger('input');
                            $('#impressionsBudgetCap').trigger('input');
                            if (adGroup.channelId == 6 || adGroup.channelId == 16 || vm.adGroup.channelId == 25) {
                                $('#creativesValidation').trigger('input');
                            }
                            if (adGroup.audienceSelectionError) {
                                $('#audienceSelectionError').show();
                            }
                            if (adGroup.endDateError)
                                showFacebookErrors();

                            if (!adGroup.endDateError || adGroup.budgetCapSpendModeId == 2)
                                $('#minBudgetError').hide();

                            if (errors.length)
                                $('html, body').animate({ scrollTop: errors.offset().top - 300 }, 100);
                            document.getElementById("minBudgetTxt").style.display = "none";

                        });
                        validationFailed = true;
                        return;
                    }
                });

                if (validationFailed)
                    return;

                vm.campaign.adGroups.forEach(function (adGroup) {
                    if (adGroup.optimizationGoalTypeId !== 3)
                        adGroup.optimizationConversionSegments = [];

                    if (adGroup.channelId == 16 || adGroup.channelId == 25) {
                        setAdGroupPublishers(adGroup, vm.publishers);
                    }

                    adGroup.audienceSegmentGroupsJSON = adGroup.audienceSegmentGroups ? JSON.stringify(adGroup.audienceSegmentGroups) : null;
                    adGroup.brandSafetySegmentsJSON = adGroup.brandSafetySegments ? JSON.stringify(adGroup.brandSafetySegments) : null;
                    adGroup.connectedTVSegmentsJSON = adGroup.connectedTVSegments ? JSON.stringify(adGroup.connectedTVSegments) : null;
                    adGroup.locationSegmentsJSON = adGroup.locationSegments ? JSON.stringify(adGroup.locationSegments) : null;
                    adGroup.conversionSegmentsJSON = adGroup.conversionSegments ? JSON.stringify(adGroup.conversionSegments) : null;
                    adGroup.optimizationConversionSegmentsJSON = adGroup.optimizationConversionSegments ? JSON.stringify(adGroup.optimizationConversionSegments) : null;
                    adGroup.publishersJSON = adGroup.publishers ? JSON.stringify(adGroup.publishers) : null;
                    adGroup.monetizationPlacementsJSON = adGroup.monetizationPlacements ? JSON.stringify(adGroup.monetizationPlacements) : null;
                    adGroup.adUnitsJSON = adGroup.adUnits ? JSON.stringify(adGroup.adUnits) : null;
                    adGroup.channelIds = _.map(adGroup.channels, 'id').toString();
                    adGroup.emailDomains = _.map(adGroup.emailDomainNames, 'value').join();

                    if (adGroup.conditions) {
                        adGroup.conditions.forEach(function (c) {
                            c.$$hashKey = undefined;
                        });
                        adGroup.conditionsJSON = JSON.stringify(adGroup.conditions);
                    }

                    adGroup.marketerId = vm.campaign.marketerId;

                    if (adGroup.scheduleModel && adGroup.dayparting == 2)
                        adGroup.schedule = JSON.stringify(adGroup.scheduleModel);
                    else
                        adGroup.schedule = '{}';

                    _.forEach(adGroup.creatives, function (c) {
                        if (c.provisionalAuditData) {
                            creativeService.setCreativeAuditData(c);
                        }
                    });

                    var recencyType = _.find($rootScope.rootVM.recencyTypes, function (rt) { return adGroup.recencyType == rt.name; });
                    if (recencyType)
                        adGroup.recencyTypeId = recencyType.id;
                    else
                        adGroup.recencyTypeId = 0;

                    if (vm.selectedMarketer.taxonomyId && !adGroup.id)
                        adGroup.taxonomyId = vm.selectedMarketer.taxonomyId;

                    var creativeApprovalOption = $("input[name=crApproveOptions]:checked").val();
                    adGroup.submitForApproval = creativeApprovalOption == 1;
                    if (!adGroup.potentialReach)
                        adGroup.potentialReach = 0;

                    let getRoundedDate = (minutes, d = new Date()) => {
                        let ms = 1000 * 60 * minutes;
                        let roundedDate = new Date(Math.floor(d.getTime() / ms) * ms);
                        return roundedDate;
                    }

                    if (adGroup.flights) {
                        adGroup.flights.forEach(function (f) {
                            f.isLifetimePacing = adGroup.budgetCapSpendModeId == 2;
                            if (!f.id) {
                                f.startDate = getRoundedDate(1, f.startDate);
                                if (f.endDate) {
                                    f.endDate = getRoundedDate(1, f.endDate);
                                    f.endDate.setSeconds(59);
                                }
                            }
                        });
                    }

                    if (!adGroup.adGroupId) {
                        adGroup.startDate = getRoundedDate(1, adGroup.startDate);
                        if (adGroup.endDate) {
                            adGroup.endDate = getRoundedDate(1, adGroup.endDate);
                            adGroup.endDate.setSeconds(59);
                        }
                    }
                    if (adGroup.channelId == 3 || adGroup.channelId == 6 || adGroup.budgetCapSpendModeId != 2) {
                        adGroup.flights[0].startDate = adGroup.startDate;
                        adGroup.flights[0].endDate = adGroup.endDate;
                    }
                });

                if (vm.campaign.advertiser)
                    vm.campaign.advertiserId = vm.campaign.advertiser.id;

                if (launch) {
                    vm.campaign.isLaunch = true;
                }

                if (vm.campaign.adGroups == 0) {
                    vm.campaign.adGroups.push(angular.copy(campaignService.getSelectedAdGroup()));
                }

                var campaignObject = angular.copy(vm.campaign);
                campaignObject.marketer = null;
                campaignObject.advertiser = null;

                hub.webapicontext.saveCampaign(campaignObject).then(function (c) {
                    vm.isInProgress = false;

                    logEvent(vm.campaign, eventStartTime);
                    if (vm.campaign.campaignId && vm.campaign.campaignId != 0)
                        vm.log("Campaign " + vm.campaign.name + " has been updated");
                    else
                        vm.log("Campaign " + vm.campaign.name + " has been created");

                    vm.rootVM.cancelCampaign(true);
                    $location.path("/activation/campaign");
                });
            }

            function logEvent(entity, startTime) {
                var data = { name: entity.name, id: entity.id };
                authService.trackEvent({ action: 'SaveCampaign', view: $location.$$path, data: JSON.stringify(data) }, { timeElapsed: ((((new Date()).getTime() - startTime.getTime()) / 1000) / 60).toFixed(2) });
            }

            vm.askForApproval = function () {
                if (!vm.campaign.campaignId)
                    return true;

                var notOnReviewExist = false;
                vm.campaign.adGroups.forEach(function (adGroup) {
                    notOnReviewExist = notOnReviewExist || vm.adGroup.adGroupStatusId != 2;
                });
                return notOnReviewExist;
            }

            function showFacebookErrors(index) {
                $('#weeklyBudget' + index).addClass('has-error has-danger');
                $('#minBudgetError' + index).show();
                $('#startDate' + index).addClass('has-error has-danger');
                $('#endDate' + index).addClass('has-error has-danger');
            }

            vm.deleteAdGroup = function (adGroup) {
                var name = adGroup.name;

                for (var i = 0; i < vm.campaign.adGroups.length; i++) {
                    if (adGroup.id == vm.campaign.adGroups[i].id) {
                        vm.campaign.adGroups.splice(i, 1);
                        vm.log('Ad group ' + name + ' has been deleted');
                    }
                }
            }

            vm.totalBudget = function () {
                if (vm.campaign && vm.campaign.adGroups && vm.campaign.adGroups.length > 0)
                    return _.sumBy(vm.campaign.adGroups, 'budgetCap')
            };

            $scope.$on("$locationChangeStart", function (event, next, current) {
                if (next.indexOf('campaign-wizard') == -1) {
                    campaignService.clearCampaignVariables();
                }
            });

            $scope.gotoHash = function (hash) {
                $anchorScroll(hash);
            };

            return vm;

        }]);
})();
