<template>
  <Card title="Universal Pixels" withDivider>
    <Box flex col space="2">
      <Box v-if="!visible.empty" flex justify="between" space="2" mb="4" wrap="wrap">
        <Box width="220px">
          <InputText
            v-model="searchQueryDebounced"
            placeholder="Search"
            name="search"
            icon="search"
          />
        </Box>
      </Box>

      <ErrorState v-if="visible.error" :error="error" @retry="retry()" />
      <EmptyState v-else-if="visible.empty" p="0" message="There are no universal pixels." />
      <Box v-else>
        <Table
          v-model:sorting="sorting"
          :data="universalPixels"
          :fields="columns"
          :loading="loading"
          :skeleton="{ rows: pageSize }"
          :defaultSorting="defaultSorting"
          hoverable
        >
          <template #name="{ value, row, hovered }">
            <TableCellName
              :hovered="hovered"
              :href="router.resolve({ name: RouteName.UniversalPixelDetails, params: { pixelId: row.id } }).path"
              clickable
              dropdownWidth="150px"
            >
              {{ value }}
              <template #dropdown="{ hide }">
                <ListItemButton @click="onViewCode(row, hide)">
                  View Pixel Code
                </ListItemButton>
                <ListItemButton v-if="permissions.digitalEvents.canDeletePixel" @click="onDeleteUniversalPixel(row, hide)">
                  Delete
                </ListItemButton>
              </template>
            </TableCellName>
          </template>

          <template #advertiserName="{ value }">
            <TableCellName>
              {{ value }}
            </TableCellName>
          </template>

          <template #marketerName="{ value }">
            <TableCellName>
              {{ value }}
            </TableCellName>
          </template>
        </Table>

        <TablePagination
          v-if="visible.pagination"
          v-bind="{ loading, pagesTotal, total, pageSizes }"
          v-model:pageSize="pageSize"
          v-model="pageNumber"
        />
      </Box>
    </Box>
  </Card>

  <UniversalPixelCodeModal v-model="codeModalOpened" :scriptText="scriptText" />

  <ModalConfirm v-bind="modalProps" />
</template>

<script setup lang="ts">
import {
  Box,
  Card,
  EmptyState,
  ErrorState,
  InputText,
  ListItemButton,
  ModalConfirm,
  Table,
  TableCellName,
  TablePagination,
  useModalConfirm,
  useTableFields,
  useTableVisibility,
} from '@lasso/luikit'
import { reversedDebouncedRef } from '@lasso/shared/utils'
import { useRouter } from 'vue-router'
import { RouteName } from '@activation-objects/router'
import { computed, ref } from 'vue'
import { useToggle } from '@vueuse/core'
import { useAccessControl } from '@activation-objects/hooks/useAccessControl'
import { useApiManual, useToast } from '@lasso/shared/hooks'
import { useActivationApi } from '@lasso/api-activation/activation'

import { UniversalPixelCodeModal } from '../UniversalPixelCodeModal'

import { allColumns } from './consts'
import { useUniversalPixelsApi } from './useUniversalPixelsApi'
import { UniversalPixelRow } from './types'

const router = useRouter()
const toast = useToast()
const api = useActivationApi()

const { permissions } = useAccessControl()
const { modalProps, confirmCritical } = useModalConfirm()

const {
  universalPixels,
  loading,
  error,
  retry,

  pageNumber,
  pageSize,
  pageSizes,
  total,
  pagesTotal,
  sorting,
  defaultSorting,
  searchQuery,
} = useUniversalPixelsApi()

const { fields: columns } = useTableFields({
  fields: allColumns,
  enabledFields: () => {
    return allColumns
      .map(column => column.id)
      .filter(id => permissions.digitalEvents.canViewPixelsFromMultipleMarketers || id !== 'marketerName')
  },
})

const searchQueryDebounced = reversedDebouncedRef(searchQuery, 300)

const visible = useTableVisibility({
  pageNumber,
  pagesTotal,
  error,
  loading,
  filtersApplied: () => Boolean(searchQuery.value),
  paginationOnFirstPage: true,
})

const codeModalPixelId = ref<number | null>(null)
const scriptText = computed(() => {
  return universalPixels.value.find(pixel => pixel.id === codeModalPixelId.value)?.scriptText ?? ''
})
const [codeModalOpened, toggleCodeModal] = useToggle()

const onViewCode = (row: UniversalPixelRow, hide: () => void) => {
  codeModalPixelId.value = row.id
  toggleCodeModal(true)
  hide()
}

const { requestThrows: deleteUniversalPixel } = useApiManual(api.deleteUniversalPixel, {
  clearCacheFor: [api.getUniversalPixels, api.getUniversalPixel],
})

const onDeleteUniversalPixel = async (row: UniversalPixelRow, hide: () => void) => {
  hide()
  try {
    const deleted = await confirmCritical({
      title: 'Delete this universal pixel?',
      subtitle: 'This action can\'t be undone.',
      onConfirm: async () => deleteUniversalPixel(row.id),
      confirmText: 'Delete',
    })

    if (deleted) {
      toast.success('Successfully deleted universal pixel')
    }
  }
  catch (error) {
    toast.error('Failed to delete universal pixel', error)
  }
}
</script>
