/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateConnectionCommand } from '../models/CreateConnectionCommand';
import type { GetAdGroupsQuery } from '../models/GetAdGroupsQuery';
import type { GetAdGroupsQueryResult } from '../models/GetAdGroupsQueryResult';
import type { GetBuilderFieldsConfigQuery } from '../models/GetBuilderFieldsConfigQuery';
import type { GetBuilderFieldsConfigQueryResult } from '../models/GetBuilderFieldsConfigQueryResult';
import type { GetCampaignsQuery } from '../models/GetCampaignsQuery';
import type { GetCampaignsQueryResult } from '../models/GetCampaignsQueryResult';
import type { GetConnectionQuery } from '../models/GetConnectionQuery';
import type { GetConnectionQueryResult } from '../models/GetConnectionQueryResult';
import type { PXCommandResult } from '../models/PXCommandResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ReportingService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param requestBody
     * @returns GetCampaignsQueryResult OK
     * @throws ApiError
     */
    public postApiReportingGetCampaigns(
        requestBody?: GetCampaignsQuery,
    ): CancelablePromise<GetCampaignsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Reporting/GetCampaigns',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetAdGroupsQueryResult OK
     * @throws ApiError
     */
    public postApiReportingGetAdGroups(
        requestBody?: GetAdGroupsQuery,
    ): CancelablePromise<GetAdGroupsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Reporting/GetAdGroups',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetBuilderFieldsConfigQueryResult OK
     * @throws ApiError
     */
    public postApiReportingGetBuilderFieldsConfig(
        requestBody?: GetBuilderFieldsConfigQuery,
    ): CancelablePromise<GetBuilderFieldsConfigQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Reporting/GetBuilderFieldsConfig',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns PXCommandResult OK
     * @throws ApiError
     */
    public postApiReportingCreateConnection(
        requestBody?: CreateConnectionCommand,
    ): CancelablePromise<PXCommandResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Reporting/CreateConnection',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetConnectionQueryResult OK
     * @throws ApiError
     */
    public postApiReportingGetConnection(
        requestBody?: GetConnectionQuery,
    ): CancelablePromise<GetConnectionQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Reporting/GetConnection',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
