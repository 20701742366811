const CHANNEL_ID_EHR = 25
const CHANNEL_ID_ENDEMIC = 16

const ENDEMIC_TYPE_DTC = 1

const ENDEMIC_FORMAT_DISPLAY = 0
const ENDEMIC_FORMAT_ENEWS = 1
const ENDEMIC_FORMAT_DISPLAY_AND_ENEWS = 2

const SUPPLY_MEDIA_TYPE_BANNER = 1
const SUPPLY_MEDIA_TYPE_ENEWS = 4

function setAdGroupPublishers(adGroup, publishers) {
  if (!adGroup.publishers) {
    adGroup.publishers = []
  }

  const endemicFormatId = adGroup.endemicFormatId ?? ENDEMIC_FORMAT_DISPLAY

  const availablePublishers = publishers
    .filter(p => {
      return (adGroup.channelId !== CHANNEL_ID_EHR || p.ehr)
        && (adGroup.endemicTypeId !== ENDEMIC_TYPE_DTC || p.dtcEndemic)
    })
    .filter(p => {
      return (p.placements ?? []).some(placement => {
        return (placement.adUnits ?? []).some(adUnit => {
          return (
            (endemicFormatId === ENDEMIC_FORMAT_DISPLAY && adUnit.mediaType === SUPPLY_MEDIA_TYPE_BANNER)
            || (endemicFormatId === ENDEMIC_FORMAT_ENEWS && adUnit.mediaType === SUPPLY_MEDIA_TYPE_ENEWS)
            || (endemicFormatId === ENDEMIC_FORMAT_DISPLAY_AND_ENEWS
              && (
                adUnit.mediaType === SUPPLY_MEDIA_TYPE_BANNER
                || adUnit.mediaType === SUPPLY_MEDIA_TYPE_ENEWS
              ))
          )
        })
      })
    })

  const ehrPublishers = availablePublishers.filter(p => p.ehr)
  const directSoldOnlyPublishers = availablePublishers.filter(p => p.directSoldOnly);

  const addPublishers = (publishersToAdd, {
    excluded, hidden = true
  }) => {
    publishersToAdd.forEach(p => {
      const existingPublisher = adGroup.publishers.find(pub => pub.id === p.id)

      if (existingPublisher) {
        existingPublisher.excluded = existingPublisher.excluded || excluded
      } else {
        p.excluded = excluded;
        p.hidden = hidden;
        adGroup.publishers.push(p);
      }
    });
  }

  const getIsPositiveTargeting = () => {
    const entitiesToCheck = [
      adGroup.publishers,
      adGroup.monetizationPlacements,
      adGroup.adUnits
    ].find(entities => entities?.length)

    return Boolean(entitiesToCheck)
      && Map.groupBy(entitiesToCheck, e => e.excluded).size < 2
      && entitiesToCheck.every(e => !e.excluded)
  }

  const isPositiveTargeting = getIsPositiveTargeting()

  switch (adGroup.channelId) {
    case CHANNEL_ID_ENDEMIC: {
      if (adGroup.targetEHR && isPositiveTargeting) {
        addPublishers(ehrPublishers, { excluded: false })
      } else if (!adGroup.targetEHR && !isPositiveTargeting) {
        addPublishers(ehrPublishers, { excluded: true })
      }

      if (!isPositiveTargeting) {
        addPublishers(directSoldOnlyPublishers, { excluded: true })
      }
      break;
    }

    case CHANNEL_ID_EHR: {
      if (!isPositiveTargeting) {
        addPublishers(ehrPublishers, { excluded: false })
        addPublishers(directSoldOnlyPublishers, { excluded: true })
      }
      break;
    }

    default:
      break;
  }

  adGroup.publishers = adGroup.publishers.map(p => {
    const { placements, ...publisher } = p

    return publisher
  })
}

export { setAdGroupPublishers };
