import { withDisposableAnchor } from './withDisposableAnchor'

export const downloadData = (data: BlobPart, fileName: string) => {
  const blob = data instanceof Blob ? data : new Blob([data], { type: 'octet/stream' })
  const url = window.URL.createObjectURL(blob)

  withDisposableAnchor((a) => {
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
  })

  window.URL.revokeObjectURL(url)
}
