<template>
  <TransitionGroup v-if="group" :name="nameInternal">
    <slot />
  </TransitionGroup>
  <Transition v-else :name="nameInternal" :mode="mode">
    <slot />
  </Transition>
</template>

<script lang="ts" setup>
import { computed, useCssModule } from 'vue'

import { TransitionsMode, TransitionsName } from './types'

const {
  name = 'fade',
  mode = 'default',
  group = false,
  disabled = false,
} = defineProps<{
  name?: TransitionsName
  mode?: TransitionsMode
  group?: boolean
  disabled?: boolean
}>()

const s = useCssModule()

const nameInternal = computed(() => disabled ? undefined : s[name])
</script>

<style module>
/* fade */
.fade:global(-enter-from) {
  opacity: 0;
}

.fade:global(-enter-to) {
  opacity: 1;
}

.fade:global(-enter-active) {
  transition: all 0.3s ease;
}

.fade:global(-leave-from) {
  opacity: 1;
}

.fade:global(-leave-to) {
  opacity: 0;
}

.fade:global(-leave-active) {
  transition: all 0.3s ease;
}

/* slide */
.slide:global(-enter-to),
.slide:global(-leave-from) {
  @apply opacity-100 scale-100 overflow-hidden transition-all;
}

.slide:global(-enter-active),
.slide:global(-leave-active) {
  @apply duration-150 ease-out overflow-hidden;
}

.slide:global(-enter-from),
.slide:global(-leave-to) {
  @apply opacity-0 max-h-0 scale-90;
}

/* slide-x */
.slide-x:global(-enter-to),
.slide-x:global(-leave-from) {
  opacity: 1;
  transition-property: width, opacity;
}

.slide-x:global(-enter-active),
.slide-x:global(-leave-active) {
  transition-duration: 150ms;
  transition-timing-function: ease-out;
  overflow: hidden;
}

.slide-x:global(-enter-from),
.slide-x:global(-leave-to) {
  opacity: 0;
  width: 0 !important;
}

/* flip - use with mode="out-in" */
.flip:global(-leave-from) {
  transform: rotateY(0);
}

.flip:global(-leave-to) {
  transform: rotateY(90deg);
}

.flip:global(-enter-from) {
  transform: rotateY(-90deg);
}

.flip:global(-enter-to) {
  transform: rotateY(0);
}

.flip:global(-leave-active),
.flip:global(-enter-active) {
  transition-duration: 300ms;
  transition-property: transform;
  transform-style: preserve-3d;
  perspective: 1000px;
  backface-visibility: hidden;
}

.flip:global(-leave-active) {
  transition-timing-function: ease-in;
}

.flip:global(-enter-active) {
  transition-timing-function: ease-out;
}
</style>
