import { Store, setActivePinia } from 'pinia'
import { Plugin } from 'vue'

export const appUnmountCleanupPlugin: Plugin = (app) => {
  app.onUnmount(() => {
    // Cleanup after vue-router
    const router = app.config.globalProperties.$router
    router?.options.history?.destroy()

    // Cleanup after pinia
    setActivePinia(undefined)
    const stores = (app.config.globalProperties.$pinia as unknown as { _s: Map<string, Store> } | undefined)?._s ?? new Map()
    stores.forEach((store) => {
      store.$dispose()
    })
  })
}
