import { createInjectionState } from '@lasso/shared/utils'
import { RefOrGetter } from '@lasso/shared/types'

import { toRef } from 'vue'

import { ButtonGroupShape, ButtonGroupSize, ButtonGroupVariant } from './types'

export type ButtonGroupProps = {
  variant: ButtonGroupVariant
  size: ButtonGroupSize
  shape: ButtonGroupShape
  isActive: (value: unknown) => (boolean | undefined)
  setActive: (value: unknown) => void
}

export const [useProvideButtonGroupProps, useButtonGroupProps] = createInjectionState((
  props: RefOrGetter<ButtonGroupProps>,
) => toRef(props))
