(function () {
    'use strict';
    angular.module('app').factory('authInterceptorService', ['$q', '$injector', '$location', '$timeout', 'localStorageService', 'logger', 'authSettings', '$browser', function ($q, $injector, $location, $timeout, localStorageService, logger, authSettings, $browser) {

        var authInterceptorServiceFactory = {};

        authInterceptorServiceFactory.urls = [];

        var _request = function (config) {
            config.headers = config.headers || {};
            var store = $injector.get('localStorageService');
            var appSettings = $injector.get('appSettings');

            authInterceptorServiceFactory.bypassUrl = appSettings.storageUrl;


            //add token only to api requests except for /token endpoint
            if (config.url.indexOf(appSettings.apiUrl) >= 0 || config.url.indexOf(appSettings.storageUrl) >= 0 || config.url.indexOf(process.env.PUBLIC_ACT_API_URL) >= 0) {

                if (config.url.indexOf('/token') !== -1)
                    return config;

                var authData = store.get('authorizationData');
                if (authData) {
                    config.headers.Authorization = 'Bearer ' + authData.token;
                }
            }

            return config;
        }

        var _responseError = function (rejection) {
            var store = $injector.get('localStorageService');
            var authData = store.get('authorizationData');

            if (rejection.status === 400 || rejection.status === 500) {
                //log validation error
                var logger = $injector.get('logger');
                if (rejection.data.length || (rejection.data.exceptionMessage && rejection.data.validationError)) {
                    try {
                        let msg = '';
                        if (typeof(rejection.data) === 'string') {
                            // data is the exceptionMessage
                            msg = rejection.data;
                        } else {
                            rejection.data.validationError = true;

                            //handle standard model validation
                            if (rejection.data.length) {
                                rejection.data.forEach(function (err) {
                                    msg += (!err.message ? err : err.message) + '<br/>';
                                });
                            } else {
                                //handle extendent model validation
                                var errors = JSON.parse(rejection.data.exceptionMessage);

                                errors.forEach(function (err) {
                                    msg += err.error + (err.value ? (' (' + err.value + ')') : '') + '<br/>';
                                });
                            }
                        }

                        // logger.logError(msg, rejection.config.url, 'API Call', true);
                        logger.logError(msg,
                            { url: rejection.config.url, error: rejection.data.exceptionMessage },
                            'Validation Error',
                            true);
                        rejection.data.logged = true;
                    } catch (e) {
                    }
                } else {
                    if (rejection.status === 400)
                        logger.logError(rejection.data.message,
                            { url: rejection.config.url, error: rejection.data },
                            'Invalid API Call',
                            true);
                    else
                        logger.logError(rejection.data.exceptionMessage,
                            { url: rejection.config.url, error: rejection.data.exceptionDetails },
                            'API Call Exception',
                            true);
                    rejection.data.logged = true;
                }
            }
            if (rejection.status === 401) {
                var authService = $injector.get('authService');
                var appSettings = $injector.get('appSettings');

                if (authData) {
                    if (authData.useRefreshTokens) {
                        $location.path('refresh');
                        return $q.reject(rejection);
                    }
                }

                //if API url call failed, logout user.
                if (
                  rejection.config.url.includes(appSettings.apiUrl)
                  && !['/pages/403', '/pages/404', '/pages/500'].includes($location.path())
                ) {
                    if (authService.isLoggedIn()) {
                        authService.setReturnLink()
                        authService.logOut();
                    }
                }
            }

            if (rejection.status === 403) {
                $injector.get('logger').getLogFn('Security', 'error')('Permission denied to access this resource.');
                $location.path('pages/403');
            }

            return $q.reject(rejection);
        };

        authInterceptorServiceFactory.request = _request;
        authInterceptorServiceFactory.responseError = _responseError;

        return authInterceptorServiceFactory;
    }]);

})();
