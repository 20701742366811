import { createInjectionState, reversedDebouncedRef } from '@lasso/shared/utils'
import { UseFormAddonsContext, useApi, useApiCombined, useApiInfinite, useApiInfiniteSkip } from '@lasso/shared/hooks'
import {
  ChannelType,
  EndemicFormatType,
  type GetListOfCreativesByMarketerQuery,
  useActivationApi,
} from '@lasso/api-activation/activation'
import { Ref, computed, reactive, ref, toRef } from 'vue'

import { RefOrGetter } from '@lasso/shared/types'

import { buildCreative } from '../utils'
import { CardCreativesFormInput } from '../types'
import { AdGroupCreative } from '../../../../shared'

export const [useProvideCreativesSearch, useCreativesSearch] = createInjectionState(({
  isCardEnabled,
  channelId,
  endemicFormatId,
  marketerId,
  accountId,
  getId,
  useFieldError,
}: {
  isCardEnabled: RefOrGetter<boolean>
  channelId: Readonly<Ref<ChannelType>>
  endemicFormatId: Readonly<Ref<EndemicFormatType | null>>
  marketerId: Readonly<Ref<number>>
  accountId: Readonly<Ref<number>>
} & UseFormAddonsContext<CardCreativesFormInput>) => {
  const searchQuery = ref('')
  const searchQueryTrimmed = computed(() => searchQuery.value.trim())
  const searchQueryDebounced = reversedDebouncedRef(searchQuery, 300)

  const total = ref(0)

  const api = useActivationApi()
  const requestData = computed((): Omit<GetListOfCreativesByMarketerQuery, 'paging'> => ({
    marketerId: marketerId.value,
    advertiserId: accountId.value,
    channelId: channelId.value,
    endemicFormatId: channelId.value === ChannelType.ENDEMIC_EHR
      ? EndemicFormatType.DISPLAY_ONLY
      : endemicFormatId.value ?? undefined,
    keyword: searchQueryTrimmed.value,
  }))

  const allCreativesApi = reactive(useApi(
    api.getListOfCreativesByMarketer,
    () => [{
      ...requestData.value,
      paging: {
        pageSize: total.value || 200,
        skip: 0,
      },
    }],
    { immediate: false, cache: true },
  ))

  const creativesApi = reactive(useApiInfinite(
    api.getListOfCreativesByMarketer,
    (page) => {
      return [{
        ...requestData.value,
        paging: {
          pageSize: page.size,
          skip: page.param,
        },
      }]
    },
    {
      ...useApiInfiniteSkip(({ responseData }) => responseData.total ?? 0),
      pageSize: 20,
      getDataArray: ({ data }) => data ?? [],
      refetch: true,
      cache: true,
      clearWhenDisabled: true,
      enabled: toRef(isCardEnabled),
    },
  ))

  const { error, retry } = useApiCombined([allCreativesApi, creativesApi])

  creativesApi.onData((data, [requestData]) => {
    if (!requestData?.paging.skip) {
      total.value = data.total ?? 0
    }
  })

  const loadAllResults = async (): Promise<AdGroupCreative[] | null> => {
    const response = await allCreativesApi.request()

    if (!response) {
      return null
    }

    return response.data?.map(buildCreative) ?? []
  }

  const searchedCreatives = computed(() => {
    return creativesApi.data.map(buildCreative)
  })

  const isShowingRecentCreatives = computed(() => {
    return !creativesApi.fetching && !searchQueryTrimmed.value && searchedCreatives.value.length > 0
  })

  const creativesError = useFieldError('creatives.creatives')

  return {
    fetching: toRef(creativesApi, 'fetching'),
    error,
    retry,
    searchQuery,
    searchQueryDebounced,
    isShowingRecentCreatives,
    requestNextPage: creativesApi.requestNextPage,
    hasNextPage: toRef(creativesApi, 'hasNextPage'),
    searchedCreatives,
    total,
    getId,
    creativesError,
    loadAllResults,
  }
})
